module.exports = {
      'save': 'Opslaan',
      'billing': 'Facturatie adres',
      'delivery': 'Levering',
      'almost_ready': 'Bijna klaar',
      'customer_data': 'Factuur- en leveringsdetails',
      'company_purchase': 'Zakelijke klant',
      'ship_to_different_address': 'Verzend naar een ander adres',
      'your_order': 'Jouw bestelling',
      'your_cart_is_empty': 'Uw winkelwagen is leeg',
      'payment': 'Betaling',
      'customer_note_placeholder': 'Opmerkingen over uw bestelling, b.v. speciale opmerkingen voor levering.',
      'cart_subtotal': 'Subtotaal winkelwagen',
      'shipping_total': 'Verzending totaal',
      'order_total': 'Totale bestelling',
      'cod': 'Rembours',
      'place_order': 'Aankoop voltooien',
      'complete_purchase': 'Complete purchase',

      'incl': 'Incl.',
      'vat': 'BTW',
      'incl_vat': 'incl. BTW',
      'by_clicking_place_order_button_you_accept_our': 'Door op de knop "Aankoop voltooien" te klikken, accepteert u onze',
      'terms_and_conditions': 'Voorwaarden.',
      'we_will_process_your_data_in_accordance_with_the': 'Wij verwerken uw gegevens in overeenstemming met de',
      'privacy_policy-text': 'Deze site maakt gebruik van cookies om u de beste surfervaring te bieden. Lees meer in ons',
      'privacy_policy': 'Privacybeleid',
      'privacy_policy-LINK': '/nl/data-protection',
      'accept-cookies': 'Accepteer cookies',
      'close': 'Sluiten',
      'required_field': 'Verplicht veld',
      'incorrect_email_address': 'Vul een geldig e-mailadres',
      'incorrect_phone_number': 'Telefoonnummer ongeldig',
      'incorrect_postal_code': 'Voer een geldige postcode in',
      'incorrect_vat_number': 'BTW-nummer ongeldig',
      'street_building_no_apartment': 'Straat, Gebouwnr., Appartement',
      'street_and_building_number_required': 'Straat en huisnummer zijn verplicht',
      'optional': 'Optioneel',

      'first_name': 'Voornaam',
      'last_name': 'Achternaam',
      'email': 'E-mailadres',
      'phone': 'Telefoonnummer',
      'address_1': 'Adres',
      'address': 'Adres',
      'city': 'Plaats/stad',
      'state': 'Staat',
      'postcode': 'Postcode',
      'country': 'Land',
      'company': 'Bedrijf',
      'vat_number': 'Btw-nummer',
      'PL': 'Polen',
      'AT': 'Oostenrijk',
      'BE': 'België',
      'HR': 'Kroatië',
      'DK': 'Denemarken',
      'EE': 'Estland',
      'IT': 'Italië',
      'LV': 'Letland',
      'LT': 'Litouwen',
      'DE': 'Duitsland',
      'SK': 'Slowakije',
      'SI': 'Slovenië',
      'SE': 'Zweden',
      'CH': 'Zwitserland',
      'UK': 'Verenigd Koninkrijk',
      'NL': 'Nederland',

      'not_paid': 'De bestelling is niet betaald.',
      'billing_and_shipping_country_must_be_the_same': 'Land van facturering en verzending moeten hetzelfde zijn',
      'thank_you': 'Dank je.',
      'order_received': 'Uw bestelling is ontvangen.',
      'order_number': 'Bestellingsnummer',
      'date': 'Datum',
      'payment_method': 'Betalingswijze',
      'order_details': 'Bestel Details',
      'quantity': 'Hoeveelheid',
      'total': 'Totaal',


}