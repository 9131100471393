import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { germany } from '../initial/vars'

import plCheckout from '../locales/pl/checkout'
import enCheckout from '../locales/en/checkout'
import deCheckout from '../locales/de/checkout'
import nlCheckout from '../locales/nl/checkout'
import plProducts from '../locales/pl/productNames'
import enProducts from '../locales/en/productNames'
import deProducts from '../locales/de/productNames'
import nlProducts from '../locales/nl/productNames'
import plStatic from '../locales/pl/staticPages'
import enStatic from '../locales/en/staticPages'
import deStatic from '../locales/de/staticPages'
import nlStatic from '../locales/nl/staticPages'
import pl from '../locales/pl/common'
import en from '../locales/en/common'
import de from '../locales/de/common'
import nl from '../locales/nl/common'

import plSlugsPl from '../locales/pl/slugsPL'
import plSlugsEn from '../locales/pl/slugsEN'
import plSlugsDe from '../locales/pl/slugsDE'
import plSlugsNl from '../locales/pl/slugsNL'
import enSlugsPl from '../locales/en/slugsPL'
import enSlugsEn from '../locales/en/slugsEN'
import enSlugsDe from '../locales/en/slugsDE'
import enSlugsNl from '../locales/en/slugsNL'
import deSlugsPl from '../locales/de/slugsPL'
import deSlugsEn from '../locales/de/slugsEN'
import deSlugsDe from '../locales/de/slugsDE'
import deSlugsNl from '../locales/de/slugsNL'
import nlSlugsPl from '../locales/nl/slugsPL'
import nlSlugsEn from '../locales/nl/slugsEN'
import nlSlugsDe from '../locales/nl/slugsDE'
import nlSlugsNl from '../locales/nl/slugsNL'

i18n.use(initReactI18next).init({
  ns: ['common', 'product', 'slugsPl', 'slugsEn', 'slugsDe', 'slugsNl'],
  defaultNS: 'common',
  resources: {
    pl: {
      common: pl,
      static: plStatic,
      product: plProducts,
      checkout: plCheckout,
      slugsPl: plSlugsPl,
      slugsEn: plSlugsEn,
      slugsDe: plSlugsDe,
      slugsNl: plSlugsNl,
    },
    en: {
      common: en,
      static: enStatic,
      product: enProducts,
      checkout: enCheckout,
      slugsPl: enSlugsPl,
      slugsEn: enSlugsEn,
      slugsDe: enSlugsDe,
      slugsNl: enSlugsNl,
    },
    de: {
      common: de,
      static: deStatic,
      product: deProducts,
      checkout: deCheckout,
      slugsPl: deSlugsPl,
      slugsEn: deSlugsEn,
      slugsDe: deSlugsDe,
      slugsNl: deSlugsNl,
    },
    nl: {
      common: nl,
      static: nlStatic,
      product: nlProducts,
      checkout: nlCheckout,
      slugsPl: nlSlugsPl,
      slugsEn: nlSlugsEn,
      slugsDe: nlSlugsDe,
      slugsNl: nlSlugsNl,
    },
  },
  fallbackLng: {
    default: germany ? ['de'] : ['pl'],
  },
  keySeparator: false,
  nsSeparator: '::',
  interpolation: {
    escapeValue: false,
  },
  debug: false,
})

export default i18n
