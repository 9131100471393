import React from 'react'
import styled from 'styled-components'

const MainButtonTypoContainer = styled.div`
  font-size: 13px;
  letter-spacing: 2.8px;
  font-weight: 600;
  line-height: 16px;
  text-transform: uppercase;
  ${({ color }) => `color: ${color}`};
  padding: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`
export default function MainButtonTypo({ text, style, color }) {
  return (
    <MainButtonTypoContainer style={style} color={color}>
      {text}
    </MainButtonTypoContainer>
  )
}
