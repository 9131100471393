module.exports = {
      'save': 'Save',
      'billing': 'Billing address',
      'delivery': 'Delivery',
      'almost_ready': 'Almost ready',
      'customer_data': 'Billing and delivery details',
      'company_purchase': 'Business Customer',
      'ship_to_different_address': 'Ship to a different address',
      'your_order': 'Your order',
      'your_cart_is_empty': 'Your cart is empty',
      'payment': 'Payment',
      'customer_note_placeholder': 'Notes about your order, e.g. special notes for delivery.',
      'cart_subtotal': 'Cart subtotal',
      'shipping_total': 'Shipping total',
      'order_total': 'Order total',
      'cod': 'Cash on delivery',
      'place_order': 'Place order',
      'complete_purchase': 'Complete purchase',

      'incl': 'Incl.',
      'vat': 'VAT',
      'incl_vat': 'Includes VAT',
      'by_clicking_place_order_button_you_accept_our': 'By clicking the "Place Order" button, you accept our',
      'terms_and_conditions': 'Terms and Conditions.',
      'we_will_process_your_data_in_accordance_with_the': 'We will process your data in accordance with the',
      'privacy_policy-text': 'This site uses cookies to offer you a better browsing experience. Find out more in our',
      'privacy_policy': 'Privacy Policy',
      'privacy_policy-LINK': '/en/data-protection',
      'accept-cookies': 'Accept cookies',
      'close': 'Close',
      'required_field': 'Required Field',
      'incorrect_email_address': 'Incorrect e-mail address',
      'incorrect_phone_number': 'Incorrect phone number',
      'incorrect_postal_code': 'Incorrect postal code',
      'incorrect_vat_number': 'Incorrect VAT number',
      'street_building_no_apartment': 'Street, Building no., Apartment',
      'street_and_building_number_required': 'Street and building number are required',
      'optional': 'Optional',

      'first_name': 'Name',
      'last_name': 'Surmane',
      'email': 'E-mail',
      'phone': 'Phone',
      'address_1': 'Address',
      'address': 'Address',
      'city': 'City',
      'state': 'State',
      'postcode': 'Postal code',
      'country': 'Country',
      'company': 'Company',
      'vat_number': 'VAT Number',
      'PL': 'Poland',
      'AT': 'Austria',
      'BE': 'Belgium',
      'HR': 'Croatia',
      'DK': 'Denmark',
      'EE': 'Estonia',
      'IT': 'Italy',
      'LV': 'Latvia',
      'LT': 'Lithuania',
      'DE': 'Germany',
      'SK': 'Slovakia',
      'SI': 'Slovenia',
      'SE': 'Sweden',
      'CH': 'Switzerland',
      'UK': 'United Kingdom',
      'NL': 'Netherlands',

      'not_paid': 'The order has not been paid.',
      'billing_and_shipping_country_must_be_the_same': 'Billing and shipping country must be the same',
      'thank_you': 'Thank you.',
      'order_received': 'Your order has been received.',
      'order_number': 'Order number',
      'date': 'Date',
      'payment_method': 'Payment method',
      'order_details': 'Order details',
      'quantity': 'Quantity',
      'total': 'Total',


}