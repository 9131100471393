import React, { useState, useContext, useEffect } from 'react'
import Logo from '../components/logo'
import CartIcon from '../components/cartIcon'
import Drawer from '../molecules/Drawer'
import MenuIcon from '../atoms/MenuIcon'
import MenuBody from '../molecules/MenuBody'
import CartBody from '../molecules/CartBody'
import { borderGray } from '../utils/theme'
import { windowGlobal, getCurrencySymbol } from '../utils/utils'
import { UsersContext } from '../context'
import styled from 'styled-components'
import Wrapper from '../components/Wrapper'
import TrustBadge from '../components/TrustBadge'
import ContactBar from './contactbar'
import { useTranslation } from 'react-i18next'
import RegularTypo from '../typography/RegularTypo'
import { breakpoints } from '../utils/theme'
import RegionMenu from '../molecules/RegionMenu'
import { useQueryParam, StringParam } from 'use-query-params'
import { countries, germany } from '../initial/vars'

const StyledHeader = styled.header`
  position: fixed;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  width: 100%;
  top: 0;
  background: white; 
  border-bottom: 1px solid ${borderGray};
  z-index: 999999997;
`

const HeaderContent = styled.div`
  padding: 16px 0px;
  display: flex; 
  justify-content: space-between;
  align-items: center;
  margin-left: -8px;
  margin-right: -8px;
`


const RegionSelector = styled.button`
  cursor: pointer;
  padding: 0;
  border: 0;
  background: none;
  position: absolute;
  right: 80px;
  white-space: nowrap;

  @media (max-width: ${breakpoints.tablet}px) {
    right: 60px;
  }
  @media (max-width: ${breakpoints.mobile}px) {
    display: none
  }
`

const Wrap = styled.div`
  display: flex; 
  justify-content: space-between;
  align-items: center;
  position: relative;
`


const Header = ({ menuLinks, hideTrustedShopsBadge, disableCart }) => {
  const { t } = useTranslation()

  const {
    cartVisible, hideCart, showCart,
    setRegion,
    region,
  } = useContext(UsersContext)

  const [_region, set_region] = useState(null)
  const [regionMenu, setRegionMenu] = useState(false)
  const [menuVisible, setMenuVisible] = useState(false)
  const showMenu = () => setMenuVisible(true)
  const hideMenu = () => setMenuVisible(false)
  const scrollWidth = windowGlobal && (windowGlobal.innerWidth - document.documentElement.offsetWidth + 'px')
  const header = windowGlobal && document.getElementsByTagName('header')[0]
  const anchorMenu = windowGlobal && document.getElementById('anchor_menu_desktop')

  useEffect(() => {
    if (menuVisible || cartVisible) {
      windowGlobal && (document.documentElement.classList.add('disabled'))
      windowGlobal && (document.documentElement.style.paddingRight = scrollWidth)
      header && (header.style.paddingRight = scrollWidth)
      anchorMenu && (anchorMenu.style.paddingRight = scrollWidth)
    } else {
      windowGlobal && (document.documentElement.classList.remove('disabled'))
      windowGlobal && (document.documentElement.style.paddingRight = "initial")
      header && (header.style.paddingRight = 'initial')
      anchorMenu && (anchorMenu.style.paddingRight = 'initial')
    }
  }, [menuVisible, cartVisible, anchorMenu, header, scrollWidth])

  const badgeZIndex = 100000

  useEffect(() => {
    const trustbadge = document.querySelectorAll('*[id^="trustbadge-container-"]')[0]
    if (trustbadge) {
      trustbadge.style.zIndex = badgeZIndex
    }
  }, [cartVisible, menuVisible]);

  const [regionParam] = useQueryParam('region', StringParam)

  useEffect(() => {
    const region = regionParam?.toUpperCase()
    if (germany && countries?.includes(region)) {
      setRegion(region)
    }
  }, [regionParam])

  useEffect(() => {
    set_region(region)
  }, [region])


  return (
    <StyledHeader>

      {germany ? <RegionMenu visible={regionMenu} setRegionMenu={setRegionMenu} setRegion={setRegion} countries={countries} /> : null}

      <Drawer
        left
        visible={menuVisible}
        hideDrawer={hideMenu}
        content={<MenuBody menuLinks={menuLinks} />}
      />
      <Drawer
        zIndex={badgeZIndex + 1}
        visible={cartVisible}
        hideDrawer={hideCart}
        content={<CartBody hideCart={hideCart} region={region} />}
      />
      <ContactBar />

      <Wrapper >

        <HeaderContent>
          <MenuIcon showDrawer={showMenu} />
          <Logo />
          <Wrap>
            {!disableCart && _region && germany && <RegionSelector onClick={() => setRegionMenu(!regionMenu)}>
              <RegularTypo text={t(`checkout::${_region}`) + ' | ' + getCurrencySymbol(_region)} />
            </RegionSelector>}
            <CartIcon showDrawer={disableCart ? undefined : showCart} disabled={disableCart} />
          </Wrap>
        </HeaderContent>
      </Wrapper>
      {!hideTrustedShopsBadge && <TrustBadge />}

    </StyledHeader>
  )
}

export default Header
