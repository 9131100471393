import React, { useContext } from 'react'
import { UsersContext } from '../../context'
import { formatPrice } from '../../utils/utils'
import { borderGray } from '../../utils/theme'
import RegularTypo from '../../typography/RegularTypo'
import SmallerTypo from '../../typography/SmallerTypo'
import { totalPrice, roundToTwoDecimals } from '../../utils/utils'
import ChangeQtyBtn from '../../atoms/ChangeQtyBtn'
import DeleteBtn from '../../atoms/DeleteBtn'
import { useTranslation } from 'react-i18next'
import { germany } from '../../initial/vars'
import styled from 'styled-components'

const SingleProduct = styled.div`
  padding: ${({ simple }) => (simple ? '0' : '0 0 15px')};
  border-bottom: ${({ simple }) => (simple ? 'none' : `1px solid ${borderGray}`)};
`

const ProductName = styled(RegularTypo)`
  h5 {
    font-size: 14px;
    line-height: 1.33333333333;
  }
`

const CartItem = ({
  index,
  name,
  image,
  options,
  price,
  qty,
  group,
  removeGroup,
  updateQuantity,
  simple,
  vatRate,
  productList,
  region
}) => {

  const removeCurrency = (price) => {
    return (price[0] === '£') ? price.substring(1) : price
  }
  
  const { t } = useTranslation()

  const _productTotal = (parseFloat(group.total) + parseFloat(group.total_tax))
    || (roundToTwoDecimals(totalPrice(group.readyProduct, vatRate) / group.readyProduct.quantity) * group.readyProduct.quantity)

  const _price = (parseFloat(group.total) + parseFloat(group.total_tax)) / group.quantity
    || parseFloat(removeCurrency(price)) / vatRate

  return (
    <SingleProduct simple={simple} productList={productList} vatRate={vatRate}  >
      <div style={{ display: 'flex', padding: simple ? '8px 0 0' : '16px 0' }}>
        {!simple && <div
          style={{
            flex: 1,
            height: 68,
            maxWidth: 70,
            marginRight: 16,
          }}
        >
          <img
            src={image}
            alt={name}
            style={{ width: '100%', height: '100%', objectFit: 'cover ' }}
          />
        </div>
        }

        <div style={{ flex: 2, marginTop: -2 }}>
          <ProductName text={t(`product::${name}`)} style={{ lineHeight: 1.4 }} />
          <>
            {group.readyProduct && group.readyProduct.kitBase_S60 && (
              <div style={{ margin: simple ? '8px 0 0' : '8px 0 12px' }}>
                {options.map((option, index) => (
                  <SmallerTypo
                    key={index}
                    grey
                    text={
                      t(option[0]) + ': ' + t(option[1])
                    }
                    style={{ lineHeight: 1.4 }}
                  />
                ))}
              </div>
            )}
          </>
        </div>
      </div>
      {!simple ?
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ChangeQtyBtn
            minus
            onClick={() => qty > 1 && updateQuantity(index, -1)}
          />
          <ChangeQtyBtn plus onClick={() => updateQuantity(index, 1)} />
          <div style={{ marginLeft: '16px' }}>
            <RegularTypo text={qty + ' × ' + price} />
          </div>
          <div style={{ marginLeft: 'auto' }}>
            <DeleteBtn onClick={() => removeGroup(group)} />
          </div>
        </div>
        :
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ margin: '6px 0 6px' }}>
            <SmallerTypo grey text={t('checkout::quantity') + ': ' + qty + ' × ' + price } style={{ lineHeight: 1.4 }} />
            <SmallerTypo grey text={t('checkout::total') + ': ' + formatPrice({ price: _productTotal, region })}
              style={{ lineHeight: 1.4 }} />
          </div>
        </div>
      }
    </SingleProduct>
  )
}

const ProductsInCart = ({ simple, productList, vatRate, productTotal, }) => {
  const { cart, removeGroup, updateQuantity, region } = useContext(UsersContext)
  const quantity = group =>
  (group.readyProduct?.kitBase_S60
    ? group.readyProduct?.kitBase_S60.quantity
    : group.readyProduct?.quantity)

  const quantity2 = group => (group.kitBase_S60 ? group.kitBase_S60.quantity : group.quantity)

  const _productList = productList || cart

  return (
    <div>
      {_productList.map((group, index) => (
        <CartItem
          name={productList ? group.name : group.readyProduct?.name}
          price={productList ? formatPrice({ price: group.price * vatRate, germany, region })
            : formatPrice({
              price: totalPrice(group.readyProduct) / quantity(group), germany,
              region
            })}
          options={productList ? group.options : group.readyProduct?.options}
          qty={productList ? quantity2(group) : quantity(group)}
          key={index}
          removeGroup={removeGroup}
          group={group}
          updateQuantity={updateQuantity}
          index={index}
          image={productList ? group.image : group.readyProduct?.image}
          simple={simple}
          vatRate={vatRate}
          productList={productList}
          productTotal={productTotal}
          region={region}
        />
      ))}
    </div>
  )
}

export default ProductsInCart
