module.exports = {
    // Static Pages
    'company_page': `
## Unternehmen
### &nbsp;
Das Unternehmen Tycho stellt Schiebe- und Faltsysteme her. Sein Angebot umfasst Falttür- und Schiebetür-Systeme für Holz und holzähnliche Stoffe, Schiebe-Innentürsysteme aus Glas und entsprechendes Zubehör. Das Unternehmen erweitert stets seine Produktpalette und führt innovative Lösungen ein. Für viele seiner Produkte meldete das Unternehmen Schutzrechte an. Die Produkte der Fa. Tycho zeichnen sich durch Höchstqualität aus. Dank optimierten Herstellungsverfahren können sie auch zu einem konkurrenzfähigen Preis angeboten werden. Tycho stellt Produkte her, die einfach zu montieren sind und einen hohen Nutzkomfort gewährleisten. Ihre Widerstandsfähigkeit und Sicherheit bestätigt eine 5-Jahre-Garantie.\n
Das Unternehmen Tycho wurde 1981 gegründet. Am Anfang stellte es Produkte für die Automotive-Industrie her. 1997 verlagerte sich der Kernbereich des Unternehmens auf Schiebe- und Falttür-Systeme. Der 2016 in Betrieb genommene Onlineshop erweiterte das direkte Vertriebsnetz.
`,



    'shipping_page': `
## Versand und Zahlung
### &nbsp;
#### Lieferungen sind nur an Lieferadressen in
#### Österreich
#### Belgien
#### Kroatien
#### Dänemark
#### Estland
#### Italien
#### Lettland
#### Litauen
#### Deutschland
#### Slowakei
#### Slowenien
#### Schweden
#### Schweiz
#### Großbritannien
#### und die Niederlande.
### &nbsp;
##### Die Zahlung ist je nach Region per PayPal, Kreditkarte und anderen Zahlungsmethoden möglich.
##### Für Käufer innerhalb der Europäischen Union enthalten die auf den Produktseiten angegebenen Preise die Mehrwertsteuer.
##### Für Käufer in der Schweiz und im Vereinigten Königreich gilt für Produkte ein Mehrwertsteuersatz von 0.
### &nbsp;
`,

// #### Versandkosten: 4.70 €
    // ##### Österreich, Belgien, Deutschland und den Niederlanden.


    'imprint_page': `
## Imprint
### &nbsp;
#### Tycho Krzysztof Włodarek
#### Inhaber: Krzysztof Marek Włodarek
#### USt-IdNr.: PL8220001767
#### &nbsp;
#### ul. Kościelna 32E
#### 05-311 Dębe Wielkie
#### Polen
#### &nbsp;
#### Phone: +49 152 183 93477
#### E-Mail: <hello@tycho.tech>
#### &nbsp;
### &nbsp;
###### Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die Sie hier finden https&#65279;://ec.europa.eu/consumers/odr/. Zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle sind wir nicht verpflichtet und nicht bereit.
`,



    'cancellation_page': `
## Widerrufsbelehrung
#### &nbsp;
#### &nbsp;
**Widerrufsrecht**

Sie haben das Recht, binnen 30 Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen.

Die Widerrufsfrist beträgt 30 Tage ab dem Tag, an dem Sie oder ein von Ihnen benannter Dritter, der nicht der Beförderer ist, die Waren in Besitz genommen haben bzw. hat.

Um Ihr Widerrufsrecht auszuüben, müssen Sie uns (Krzysztof Włodarek, ul. Kościelna 32E, 05-311 Dębe Wielkie, info@tycho.pl, Polen, Telefon: +49 152 183 93477) mittels einer eindeutigen Erklärung (z. B. ein mit der Post versandter Brief, Telefax oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können dafür das beigefügte Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.

Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.

#### &nbsp;
**Folgen des Widerrufs**

Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen 30 Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet. Wir können die Rückzahlung verweigern, bis wir die Waren wieder zurückerhalten haben oder bis Sie den Nachweis erbracht haben, dass Sie die Waren zurückgesandt haben, je nachdem, welches der frühere Zeitpunkt ist.

Sie haben die Waren unverzüglich und in jedem Fall spätestens binnen 30 Tagen ab dem Tag, an dem Sie uns über den Widerruf dieses Vertrags unterrichten, an uns zurückzusenden oder zu übergeben. Die Frist ist gewahrt, wenn Sie die Waren vor Ablauf der Frist von 30 Tagen absenden.

Wir tragen die Kosten der Rücksendung der Waren, allerdings müssen die folgenden Bedingungen erfüllt werden:

1. Sie müssen uns per E-Mail (hello@tycho.tech) oder telefonisch über Ihren Wunsch, ein Paket zurückzusenden, informieren.
2. Wir senden Ihnen per E-Mail ein Retouren-Label zu, das Sie ausdrucken und auf die verpackten Produkte aufkleben müssen.
3. Begeben Sie sich zum nächstgelegenen GLS PaketShop, um das Paket aufzugeben. (GLS PaketShop’s sind zu finden unter https://www.gls-one.de/de/parcelShops)
4. Sobald das Paket bei uns ankommt, erhalten Sie das Geld zurück.


#### &nbsp;
**Muster-Widerrufsformular**

*Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses Formular aus und senden Sie es zurück.*
##### – An: Tycho Krzysztof Włodarek, ul. Kościelna 32E, 05-311 Dębe Wielkie, Polen, hello@tycho.tech
##### – Hiermit widerrufe(n) ich/wir den von mir/uns abgeschlossenen Vertrag über den Kauf der folgenden Waren die Erbringung der folgenden Dienstleistung:
##### – Erhalten am:
##### – Name des/der Verbraucher(s):
##### – Anschrift des/der Verbraucher(s):
##### – Datum:
##### – Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier):

#### &nbsp;
#### &nbsp;
*Widerrufsbelehrung erstellt mit dem Trusted Shops Rechtstexter in Kooperation mit Wilde Beuger Solmecke Rechtsanwälte.*

`,



    'data_protection_page': `
## Datenschutz
#### &nbsp;
##### Wir freuen uns über Ihr Interesse an unserem Online-Shop. Der Schutz Ihrer Privatsphäre ist für uns sehr wichtig. Nachstehend informieren wir Sie ausführlich über den Umgang mit Ihren Daten.
#### &nbsp;
#### &nbsp;
**1. Zugriffsdaten und Hosting**

Sie können unsere Webseiten besuchen, ohne Angaben zu Ihrer Person zu machen. Bei jedem Aufruf einer Webseite speichert der Webserver lediglich automatisch ein sogenanntes Server-Logfile, das z.B. den Namen der angeforderten Datei, Ihre IP-Adresse, Datum und Uhrzeit des Abrufs, übertragene Datenmenge und den anfragenden Provider (Zugriffsdaten) enthält und den Abruf dokumentiert.

Diese Zugriffsdaten werden ausschließlich zum Zwecke der Sicherstellung eines störungsfreien Betriebs der Seite sowie der Verbesserung unseres Angebots ausgewertet. Dies dient gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO der Wahrung unserer im Rahmen einer Interessensabwägung überwiegenden berechtigten Interessen an einer korrekten Darstellung unseres Angebots. Alle Zugriffsdaten werden spätestens sieben Tage nach Ende Ihres Seitenbesuchs gelöscht.

#### &nbsp;
**2. Datenerhebung und -verwendung zur Vertragsabwicklung**

Wir erheben personenbezogene Daten, wenn Sie uns diese im Rahmen Ihrer Bestellung oder bei einer Kontaktaufnahme mit uns (z.B. per Kontaktformular oder E-Mail) mitteilen. Pflichtfelder werden als solche gekennzeichnet, da wir in diesen Fällen die Daten zwingend zur Vertragsabwicklung, bzw. zur Bearbeitung Ihrer Kontaktaufnahme benötigen und Sie ohne deren Angabe die Bestellung nicht abschließen, bzw. die Kontaktaufnahme nicht versenden können. Welche Daten erhoben werden, ist aus den jeweiligen Eingabeformularen ersichtlich. Wir verwenden die von ihnen mitgeteilten Daten gemäß Art. 6 Abs. 1 S. 1 lit. b DSGVO zur Vertragsabwicklung und Bearbeitung Ihrer Anfragen. Nach vollständiger Abwicklung des Vertrages werden Ihre Daten für die weitere Verarbeitung eingeschränkt und nach Ablauf etwaiger steuer- und handelsrechtlichen Aufbewahrungsfristen gelöscht, sofern Sie nicht ausdrücklich in eine weitere Nutzung Ihrer Daten eingewilligt haben oder wir uns eine darüber hinausgehende Datenverwendung vorbehalten, die gesetzlich erlaubt ist und über die wir Sie in dieser Erklärung informieren.

#### &nbsp;
**3. Datenweitergabe**

Zur Vertragserfüllung gemäß Art. 6 Abs. 1 S. 1 lit. b DSGVO geben wir Ihre Daten an das mit der Lieferung beauftragte Versandunternehmen weiter, soweit dies zur Lieferung bestellter Waren erforderlich ist. Je nach dem, welchen Zahlungsdienstleister Sie im Bestellprozess auswählen, geben wir zur Abwicklung von Zahlungen die hierfür erhobenen Zahlungsdaten an das mit der Zahlung beauftragte Kreditinstitut und ggf. von uns beauftragte Zahlungsdienstleister weiter bzw. an den ausgewählten Zahlungsdienst. Zum Teil erheben die ausgewählten Zahlungsdienstleister diese Daten auch selbst, soweit Sie dort ein Konto anlegen. In diesem Fall müssen Sie sich im Bestellprozess mit Ihren Zugangsdaten bei dem Zahlungsdienstleister anmelden. Es gilt insoweit die Datenschutzerklärung des jeweiligen Zahlungsdienstleisters.

Datenweitergabe an Versanddienstleister
Sofern Sie uns hierzu während oder nach Ihrer Bestellung Ihre ausdrückliche Einwilligung erteilt haben, geben wir aufgrund dieser gemäß Art. 6 Abs. 1 S. 1 lit. a DSGVO Ihre E-Mail-Adresse und Telefonnummer an den ausgewählten Versanddienstleister weiter, damit dieser vor Zustellung zum Zwecke der Lieferungsankündigung bzw. -abstimmung Kontakt mit Ihnen aufnehmen kann.

Die Einwilligung kann jederzeit durch eine Nachricht an die unten beschriebene Kontaktmöglichkeit oder direkt gegenüber dem Versanddienstleister unter der im Folgenden aufgeführten Kontaktadresse widerrufen werden. Nach Widerruf löschen wir Ihre hierfür angegebenen Daten, soweit Sie nicht ausdrücklich in eine weitere Nutzung Ihrer Daten eingewilligt haben oder wir uns eine darüber hinausgehende Datenverwendung vorbehalten, die gesetzlich erlaubt ist und über die wir Sie in dieser Erklärung informieren.

#### Furgonetka Sp. z o. o. Sp. k.
#### ul. Inżynierska 8
#### 03-422 Warszawa
#### Polen 

#### &nbsp;
**4. Integration des Trusted Shops Trustbadge**

Zur Anzeige unseres Trusted Shops Gütesiegels und der gesammelten Bewertungen sowie zum Angebot der Trusted Shops Produkte für Käufer nach einer Bestellung ist auf dieser Webseite das Trusted Shops Trustbadge eingebunden.

Dies dient der Wahrung unserer im Rahmen einer Interessensabwägung überwiegenden berechtigten Interessen an einer optimalen Vermarktung unseres Angebots gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO. Das Trustbadge und die damit beworbenen Dienste sind ein Angebot der Trusted Shops GmbH, Subbelrather Str. 15C, 50823 Köln.
Bei dem Aufruf des Trustbadge speichert der Webserver automatisch ein sogenanntes Server-Logfile, das z.B. Ihre IP-Adresse, Datum und Uhrzeit des Abrufs, übertragene Datenmenge und den anfragenden Provider (Zugriffsdaten) enthält und den Abruf dokumentiert. Diese Zugriffsdaten werden nicht ausgewertet und spätestens sieben Tagen nach Ende Ihres Seitenbesuchs automatisch überschrieben.
Weitere personenbezogene Daten werden lediglich an Trusted Shops übertragen, soweit Sie hierzu eingewilligt haben, sich nach Abschluss einer Bestellung für die Nutzung von Trusted Shops Produkten entscheiden oder sich bereits für die Nutzung registriert haben. In diesem Fall gilt die zwischen Ihnen und Trusted Shops getroffene vertragliche Vereinbarung.

#### &nbsp;
**5. Cookies und Webanalyse**

Um den Besuch unserer Website attraktiv zu gestalten und die Nutzung bestimmter Funktionen zu ermöglichen, um passende Produkte anzuzeigen oder zur Marktforschung verwenden wir auf verschiedenen Seiten sogenannte Cookies. Dies dient der Wahrung unserer im Rahmen einer Interessensabwägung überwiegenden berechtigten Interessen an einer optimierten Darstellung unseres Angebots gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO. Cookies sind kleine Textdateien, die automatisch auf Ihrem Endgerät gespeichert werden. Einige der von uns verwendeten Cookies werden nach Ende der Browser-Sitzung, also nach Schließen Ihres Browsers, wieder gelöscht (sog. Sitzungs-Cookies). Andere Cookies verbleiben auf Ihrem Endgerät und ermöglichen uns, Ihren Browser beim nächsten Besuch wiederzuerkennen (persistente Cookies). Die Dauer der Speicherung können Sie der Übersicht in den Cookie-Einstellungen Ihres Webbrowsers entnehmen. Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und einzeln über deren Annahme entscheiden oder die Annahme von Cookies für bestimmte Fälle oder generell ausschließen. Jeder Browser unterscheidet sich in der Art, wie er die Cookie-Einstellungen verwaltet. Diese ist in dem Hilfemenü jedes Browsers beschrieben, welches Ihnen erläutert, wie Sie Ihre Cookie-Einstellungen ändern können. Diese finden Sie für die jeweiligen Browser unter den folgenden Links:
Internet Explorer™: http://windows.microsoft.com/de-DE/windows-vista/Block-or-allow-cookies
Safari™: https://support.apple.com/kb/ph21411?locale=de_DE
Chrome™: http://support.google.com/chrome/bin/answer.py?hl=de&hlrm=en&answer=95647
Firefox™ https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen
Opera™ : http://help.opera.com/Windows/10.20/de/cookies.html

Bei der Nichtannahme von Cookies kann die Funktionalität unserer Website eingeschränkt sein.

Einsatz von Google (Universal) Analytics zur Webanalyse
Soweit Sie hierzu Ihre Einwilligung nach Art. 6 Abs. 1 S. 1 lit. a DSGVO erteilt haben, setzt diese Website zum Zweck der Webseitenanalyse Google (Universal) Analytics ein, einen Webanalysedienst der Google LLC (www.google.de). Google (Universal) Analytics verwendet Methoden, die eine Analyse der Benutzung der Website durch Sie ermöglichen, wie zum Beispiel Cookies. Die automatisch erhobenen Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Durch die Aktivierung der IP-Anonymisierung auf dieser Webseite wird dabei die IP-Adresse vor der Übermittlung innerhalb der Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte anonymisierte IP-Adresse wird grundsätzlich nicht mit anderen Daten von Google zusammengeführt. Nach Zweckfortfall und Ende des Einsatzes von Google Analytics durch uns werden die in diesem Zusammenhang erhobenen Daten gelöscht.

Die Google LLC hat ihren Hauptsitz in den USA und ist zertifiziert unter dem EU-US-Privacy Shield. Ein aktuelles Zertifikat kann hier eingesehen werden. Aufgrund dieses Abkommens zwischen den USA und der Europäischen Kommission hat letztere für unter dem Privacy Shield zertifizierte Unternehmen ein angemessenes Datenschutzniveau festgestellt.

Sie können Ihre Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen, indem sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren: http://tools.google.com/dlpage/gaoptout?hl=de . Hierdurch wird die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) sowie die Verarbeitung dieser Daten durch Google verhindert.

Alternativ zum Browser-Plugin können Sie diesen Link klicken, um die Erfassung durch Google Analytics auf dieser Website zukünftig zu verhindern. Dabei wird ein Opt-Out-Cookie auf Ihrem Endgerät abgelegt. Löschen Sie Ihre Cookies, werden Sie erneut um Erteilung Ihrer Einwilligung gebeten.

#### &nbsp;
**6. Kontaktmöglichkeiten und Ihre Rechte**

Als Betroffener haben Sie folgende Rechte:

gemäß Art. 15 DSGVO das Recht, in dem dort bezeichneten Umfang Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten zu verlangen;
gemäß Art. 16 DSGVO das Recht, unverzüglich die Berichtigung unrichtiger oder Vervollständigung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen;
gemäß Art. 17 DSGVO das Recht, die Löschung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen, soweit nicht die weitere Verarbeitung
– zur Ausübung des Rechts auf freie Meinungsäußerung und Information;
– zur Erfüllung einer rechtlichen Verpflichtung;
– aus Gründen des öffentlichen Interesses oder
– zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
erforderlich ist;
gemäß Art. 18 DSGVO das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, soweit
– die Richtigkeit der Daten von Ihnen bestritten wird;
– die Verarbeitung unrechtmäßig ist, Sie aber deren Löschung ablehnen;
– wir die Daten nicht mehr benötigen, Sie diese jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen oder
– Sie gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung eingelegt haben;
gemäß Art. 20 DSGVO das Recht, Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesebaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen;
gemäß Art. 77 DSGVO das Recht, sich bei einer Aufsichtsbehörde zu beschweren. In der Regel können Sie sich hierfür an die Aufsichtsbehörde Ihres üblichen Aufenthaltsortes oder Arbeitsplatzes oder unseres Unternehmenssitzes wenden.

Bei Fragen zur Erhebung, Verarbeitung oder Nutzung Ihrer personenbezogenen Daten, bei Auskünften, Berichtigung, Sperrung oder Löschung von Daten sowie Widerruf erteilter Einwilligungen oder Widerspruch gegen eine bestimmte Datenverwendung wenden Sie sich bitte direkt an uns über die Kontaktdaten in unserem Impressum.


#### &nbsp;
**Widerspruchsrecht**

Soweit wir zur Wahrung unserer im Rahmen einer Interessensabwägung überwiegenden berechtigten Interessen personenbezogene Daten wie oben erläutert verarbeiten, können Sie dieser Verarbeitung mit Wirkung für die Zukunft widersprechen. Erfolgt die Verarbeitung zu Zwecken des Direktmarketings, können Sie dieses Recht jederzeit wie oben beschrieben ausüben. Soweit die Verarbeitung zu anderen Zwecken erfolgt, steht Ihnen ein Widerspruchsrecht nur bei Vorliegen von Gründen, die sich aus Ihrer besonderen Situation ergeben, zu.

Nach Ausübung Ihres Widerspruchsrechts werden wir Ihre personenbezogenen Daten nicht weiter zu diesen Zwecken verarbeiten, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder wenn die Verarbeitung der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen dient.

Dies gilt nicht, wenn die Verarbeitung zu Zwecken des Direktmarketings erfolgt. Dann werden wir Ihre personenbezogenen Daten nicht weiter zu diesem Zweck verarbeiten. 

#### &nbsp;
*Datenschutzerklärung erstellt mit dem Trusted Shops Rechtstexter in Kooperation mit Wilde Beuger Solmecke Rechtsanwälte.*
`,




    'terms_and_conditions_page': `
## Allgemeine Geschäftsbedingungen
#### &nbsp;
#### &nbsp;
**1. Geltungsbereich**

Für alle Bestellungen über unseren Online-Shop durch Verbraucher und Unternehmer gelten die nachfolgenden AGB.

Verbraucher ist jede natürliche Person, die ein Rechtsgeschäft zu Zwecken abschließt, die überwiegend weder ihrer gewerblichen noch ihrer selbständigen beruflichen Tätigkeit zugerechnet werden können. Unternehmer ist eine natürliche oder juristische Person oder eine rechtsfähige Personengesellschaft, die bei Abschluss eines Rechtsgeschäfts in Ausübung ihrer gewerblichen oder selbständigen beruflichen Tätigkeit handelt.

Gegenüber Unternehmern gelten diese AGB auch für künftige Geschäftsbeziehungen, ohne dass wir nochmals auf sie hinweisen müssten. Verwendet der Unternehmer entgegenstehende oder ergänzende Allgemeine Geschäftsbedingungen, wird deren Geltung hiermit widersprochen; sie werden nur dann Vertragsbestandteil, wenn wir dem ausdrücklich zugestimmt haben.

#### &nbsp;
**2. Vertragspartner, Vertragsschluss**

Der Kaufvertrag kommt zustande mit Tycho Krzysztof Włodarek.

Mit Einstellung der Produkte in den Online-Shop geben wir ein verbindliches Angebot zum Vertragsschluss über diese Artikel ab. Sie können unsere Produkte zunächst unverbindlich in den Warenkorb legen und Ihre Eingaben vor Absenden Ihrer verbindlichen Bestellung jederzeit korrigieren, indem Sie die hierfür im Bestellablauf vorgesehenen und erläuterten Korrekturhilfen nutzen. Der Vertrag kommt zustande, indem Sie durch Anklicken des Bestellbuttons das Angebot über die im Warenkorb enthaltenen Waren annehmen. Unmittelbar nach dem Absenden der Bestellung erhalten Sie noch einmal eine Bestätigung per E-Mail.

#### &nbsp;
**3. Vertragssprache, Vertragstextspeicherung**

Die für den Vertragsschluss zur Verfügung stehenden Sprachen sind Deutsch und Englisch.

Wir speichern den Vertragstext und senden Ihnen die Bestelldaten und unsere AGB per E-Mail zu. Der Vertragstext ist aus Sicherheitsgründen nicht mehr über das Internet zugänglich.

#### &nbsp;
**4. Lieferbedingungen**

Zuzüglich zu den angegebenen Produktpreisen kommen noch Versandkosten hinzu. Näheres zur Höhe der Versandkosten erfahren Sie bei den Angeboten.

Wir liefern nur im Versandweg. Eine Selbstabholung der Ware ist leider nicht möglich.

Wir liefern nicht an Packstationen.

#### &nbsp;
**5. Bezahlung**

In unserem Shop stehen Ihnen grundsätzlich die folgenden Zahlungsarten zur Verfügung:

PayPal
Im Bestellprozess werden Sie auf die Webseite des Online-Anbieters PayPal weitergeleitet. Um den Rechnungsbetrag über PayPal bezahlen zu können, müssen Sie dort registriert sein bzw. sich erst registrieren, mit Ihren Zugangsdaten legitimieren und die Zahlungsanweisung an uns bestätigen. Nach Abgabe der Bestellung im Shop fordern wir PayPal zur Einleitung der Zahlungstransaktion auf.
Die Zahlungstransaktion wird durch PayPal unmittelbar danach automatisch durchgeführt. Weitere Hinweise erhalten Sie beim Bestellvorgang.

Sofort
Nach Abgabe der Bestellung werden Sie auf die Webseite des Online-Anbieters Sofort GmbH weitergeleitet. Um den Rechnungsbetrag über Sofort bezahlen zu können, müssen Sie über ein für die Teilnahme an Sofort freigeschaltetes Online-Banking-Konto mit PIN/TAN-Verfahren verfügen, sich entsprechend legitimieren und die Zahlungsanweisung an uns bestätigen. Weitere Hinweise erhalten Sie beim Bestellvorgang. Die Zahlungstransaktion wird unmittelbar danach von Sofort durchgeführt und Ihr Konto belastet.

#### &nbsp;
**6. Eigentumsvorbehalt**

Die Ware bleibt bis zur vollständigen Bezahlung unser Eigentum.
Für Unternehmer gilt ergänzend: Wir behalten uns das Eigentum an der Ware bis zur vollständigen Begleichung aller Forderungen aus einer laufenden Geschäftsbeziehung vor. Sie dürfen die Vorbehaltsware im ordentlichen Geschäftsbetrieb weiterveräußern; sämtliche aus diesem Weiterverkauf entstehenden Forderungen treten Sie – unabhängig von einer Verbindung oder Vermischung der Vorbehaltsware mit einer neuen Sache – in Höhe des Rechnungsbetrages an uns im Voraus ab, und wir nehmen diese Abtretung an. Sie bleiben zur Einziehung der Forderungen ermächtigt, wir dürfen Forderungen jedoch auch selbst einziehen, soweit Sie Ihren Zahlungsverpflichtungen nicht nachkommen.

#### &nbsp;
**7. Transportschäden**

Für Verbraucher gilt:
Werden Waren mit offensichtlichen Transportschäden angeliefert, so reklamieren Sie solche Fehler bitte möglichst sofort beim Zusteller und nehmen Sie bitte unverzüglich Kontakt zu uns auf. Die Versäumung einer Reklamation oder Kontaktaufnahme hat für Ihre gesetzlichen Ansprüche und deren Durchsetzung, insbesondere Ihre Gewährleistungsrechte, keinerlei Konsequenzen. Sie helfen uns aber, unsere eigenen Ansprüche gegenüber dem Frachtführer bzw. der Transportversicherung geltend machen zu können.

Für Unternehmer gilt:
Die Gefahr des zufälligen Untergangs und der zufälligen Verschlechterung geht auf Sie über, sobald wir die Sache dem Spediteur, dem Frachtführer oder der sonst zur Ausführung der Versendung bestimmten Person oder Anstalt ausgeliefert haben. Unter Kaufleuten gilt die in § 377 HGB geregelte Untersuchungs- und Rügepflicht. Unterlassen Sie die dort geregelte Anzeige, so gilt die Ware als genehmigt, es sei denn, dass es sich um einen Mangel handelt, der bei der Untersuchung nicht erkennbar war. Dies gilt nicht, falls wir einen Mangel arglistig verschwiegen haben.

#### &nbsp;
**8. Gewährleistung und Garantien**

Es gilt das gesetzliche Mängelhaftungsrecht. Informationen zu gegebenenfalls geltenden zusätzlichen Garantien und deren genaue Bedingungen finden Sie jeweils beim Produkt und auf besonderen Informationsseiten im Onlineshop.

#### &nbsp;
**9. Haftung**

Für Ansprüche aufgrund von Schäden, die durch uns, unsere gesetzlichen Vertreter oder Erfüllungsgehilfen verursacht wurden, haften wir stets unbeschränkt

bei Verletzung des Lebens, des Körpers oder der Gesundheit,
bei vorsätzlicher oder grob fahrlässiger Pflichtverletzung,
bei Garantieversprechen, soweit vereinbart, oder
soweit der Anwendungsbereich des Produkthaftungsgesetzes eröffnet ist.
Bei Verletzung wesentlicher Vertragspflichten, deren Erfüllung die ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglicht und auf deren Einhaltung der Vertragspartner regelmäßig vertrauen darf, (Kardinalpflichten) durch leichte Fahrlässigkeit von uns, unseren gesetzlichen Vertretern oder Erfüllungsgehilfen ist die Haftung der Höhe nach auf den bei Vertragsschluss vorhersehbaren Schaden begrenzt, mit dessen Entstehung typischerweise gerechnet werden muss. Im Übrigen sind Ansprüche auf Schadensersatz ausgeschlossen.

#### &nbsp;
**10. Verhaltenskodex**

Folgenden Verhaltenskodizes haben wir uns unterworfen:
Trusted Shops Qualitätskriterien
https&#65279;://www.trustedshops.com/tsdocument/%20TS_QUALITY_CRITERIA_de.pdf

#### &nbsp;
**11. Streitbeilegung**

Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die Sie hier finden https&#65279;://ec.europa.eu/consumers/odr/.
Zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle sind wir nicht verpflichtet und nicht bereit.

#### &nbsp;
**12. Schlussbestimmungen**

Sind Sie Kaufmann im Sinne des Handelsgesetzbuches, juristische Person des öffentlichen Rechts oder öffentlich-rechtliches Sondervermögen, ist ausschließlicher Gerichtsstand für alle Streitigkeiten aus Vertragsverhältnissen zwischen uns und Ihnen unser Geschäftssitz.

#### &nbsp;
*AGB erstellt mit dem Trusted Shops Rechtstexter in Kooperation mit Wilde Beuger Solmecke Rechtsanwälte.*

`

}