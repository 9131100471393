import React, { useContext, useState, useEffect } from 'react'
import { UsersContext } from '../../context'
import { Link } from 'gatsby'
import RegularTypo from '../../typography/RegularTypo'
import BigTypo from '../../typography/BigTypo'
import { borderGray } from '../../utils/theme'
import { formatPrice } from '../../utils/utils'
import GoToCheckoutButton from '../MainButton'
import CloseX from '../../atoms/CloseX'
import '../../additionalStyles/collapse.css'
import ProductsInCartList from '../Configurator/ProductsInCartList'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const CartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const CartHeader = styled.div`
  border-bottom: 1px solid ${borderGray};
  padding: 45px 16px 17px;
  display: flex;
  justify-content: space-between;
`

const StyledCartBody = styled.div`
  height: calc(100% - 207px);
  overflow-y: scroll;
  min-height: 120px;
  padding: 0 12px;
`

const CartFooter = styled.div`
  margin-top: -1px;
  padding: 16px 16px 20px;
  border-top: 1px solid ${borderGray};
`

const EmptyCartInfo = styled.div`
  height: calc(100% - 110px);
  display: flex;
  align-items: center;
  justify-content: center;
`

const CartBody = ({ region }) => {
  const { cart, cartTotal, hideCart } = useContext(UsersContext)
  const [empty, setEmpty] = useState(true)

  useEffect(() => {
    setEmpty(cart.length === 0 ? true : false)
  }, [cart])

  const { t } = useTranslation()

  return (
    <CartWrapper>
      <CartHeader>
        <BigTypo text={t('Koszyk')} style={{ fontSize: 18, marginTop: 8 }} />
        <CloseX onClick={() => hideCart()} />
      </CartHeader>

      {!empty ? (
        <StyledCartBody>
          <ProductsInCartList />
        </StyledCartBody>
      ) : (
        <EmptyCartInfo> {t('Twój koszyk jest pusty.')} </EmptyCartInfo>
      )}

      {!empty ? (
        <CartFooter>
          <div style={{ marginBottom: 16 }}>
            <RegularTypo
              text={`${t('Wartość koszyka')}: ${formatPrice({ price: cartTotal, region })}`}
            />
          </div>
          <Link to={'/checkout'}>
            <GoToCheckoutButton text={t('Przejdź do kasy')} />
          </Link>
        </CartFooter>
      ) : null}
    </CartWrapper>
  )
}

export default CartBody
