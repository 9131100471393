import React from 'react'
import { superLight, breakpoints } from '../utils/theme'
import styled from 'styled-components'
import Wrapper from '../components/Wrapper'
import { germany } from '../initial/vars'
import { useTranslation } from 'react-i18next'

const StyledContactBar = styled.div`
  white-space: nowrap;
  overflow: auto;
  font-size: 13px;
  font-weight: 400;
  background-color: ${superLight};
  padding: 4px 0 3px;
  z-index: 10000;

  a {
    text-decoration: none;
  }

  .bar_desktop {
      display: block;
  }
  .bar_mobile {
      display: none;
  }

  @media (max-width: ${breakpoints.mobile}px) {
    .bar_desktop {
      display: none;
    }
    .bar_mobile {
      display: block;
      line-height: 1.3;
    }
  }
 
`
const phoneNumber = germany ? '+49 152 183 93477' : '+48 513 589 347'
const emailAddress = germany ? 'hello@tycho.tech' : 'sklep@tycho.pl'
const phoneNumberWithoutSpaces = phoneNumber.split(' ').join('')

const ContactBar = () => {

  const { t } = useTranslation()

  return (
    <StyledContactBar>
      <Wrapper>
        <>
          <div className="bar_desktop">
            {t('ContactBarInfo1')}
            <a href={`tel:${phoneNumberWithoutSpaces}`}> {phoneNumber} </a>
            {t('ContactBarInfo2')}
            <a href={`mailto:${emailAddress}`}> {emailAddress} </a>
          </div>
          <div className="bar_mobile">
            {t('ContactBarInfo3')}
            <a href={`tel:${phoneNumberWithoutSpaces}`}> {phoneNumber} </a>
            {t('ContactBarInfo4')}
            <a href={`mailto:${emailAddress}`}> {emailAddress} </a>
          </div>
        </>
      </Wrapper>
    </StyledContactBar>
  )
}

export default ContactBar
