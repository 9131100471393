import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Header from './header'
import Footer from './footer'
import './layout.css'
import { UsersContextProvider } from '../context'
import styled from 'styled-components'
// i18n from '../components/i18n' import is needed
// eslint-disable-next-line no-unused-vars
import i18n from '../components/i18n'

const StyledLayout = styled.div`
 display: flex;
 min-height: 100vh;
 flex-direction: column;
`

const Layout = ({ disableCart, hideTrustedShopsBadge, children }) => {
 const data = useStaticQuery(graphql`
  query SiteTitleQuery {
   site {
    siteMetadata {
     title
    }
   }
  }
 `)

 return (
  <UsersContextProvider>
   <StyledLayout>
    <Header
     siteTitle={data.site.siteMetadata.title}
     hideTrustedShopsBadge={hideTrustedShopsBadge}
     disableCart={disableCart}
    />
    <div>
     <main style={{ marginTop: 93 }}>{children}</main>
    </div>
    <Footer />
   </StyledLayout>
  </UsersContextProvider>
 )
}

export default Layout
