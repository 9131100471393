import React from 'react'
import styled from 'styled-components'
import MainButtonTypo from '../../typography/MainButtonTypo'
import { tychoNavy, inactiveGray } from '../../utils/theme'

const MainButtonWrapper = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ disabled }) => (disabled ? inactiveGray : tychoNavy)};
  cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
  user-select: none;
`

export default function ProductCard({ text, disabled }) {
  return (
    <MainButtonWrapper disabled={disabled}>
      <MainButtonTypo color="white" text={text} />
    </MainButtonWrapper>
  )
}
