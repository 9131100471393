module.exports = {
      'save': 'Speichern',
      'billing': 'Rechnungsadresse',
      'delivery': 'Lieferung',
      'almost_ready': 'Fast geschafft',
      'customer_data': 'Angaben zur Abrechnung und Lieferung',
      'company_purchase': 'Geschäftskunde',
      'ship_to_different_address': 'Zu einer anderen Adresse verschicken',
      'your_order': 'Deine Bestellung',
      'your_cart_is_empty': 'Ihr Warenkorb ist leer',
      'payment': 'Zahlung',
      'customer_note_placeholder': 'Anmerkungen zu deiner Bestellung, z.B. besondere Hinweise für die Lieferung.',
      'cart_subtotal': 'Zwischensumme',
      'shipping_total': 'Versand',
      'order_total': 'Gesamtsumme',
      'cod': 'Barzahlung bei Lieferung',
      'place_order': 'Bestellung abschicken',
      'complete_purchase': 'Kauf abschließen',

      'incl': 'Inkl.',
      'vat': 'MwSt.',
      'incl_vat': 'Inkl. MwSt.',
      'by_clicking_place_order_button_you_accept_our': 'Indem Sie auf „Bestellung abschicken“ klicken, akzeptieren Sie unsere',
      'terms_and_conditions': 'AGB.',
      'we_will_process_your_data_in_accordance_with_the': 'Wir verarbeiten Ihre Daten in Übereinstimmung mit der',
      'privacy_policy-text': 'Diese Website verwendet Cookies, um Ihnen das beste Surferlebnis zu bieten. Erfahren Sie mehr in unserer',
      'privacy_policy': 'Datenschutz',
      'privacy_policy-LINK': '/de/datenschutz',
      'accept-cookies': 'Cookies akzeptieren',
      'close': 'Schließen',
      'required_field': 'Pflichtfeld',
      'incorrect_email_address': 'Falsche E-Mail Adresse',
      'incorrect_phone_number': 'Falsche Telefonnummer',
      'incorrect_postal_code': 'Falsche Postleitzahl',
      'incorrect_vat_number': 'Falsche USt-IdNr.',
      'street_building_no_apartment': 'Straße, Hausnr., Wohnung',
      'street_and_building_number_required': 'Straße und Hausnummer sind erforderlich',
      'optional': 'Optional',

      'first_name': 'Vorname',
      'last_name': 'Nachname',
      'email': 'E-mail',
      'phone': 'Telefon',
      'address_1': 'Adresse',
      'address': 'Adresse',
      'city': 'Stadt',
      'state': 'State',
      'postcode': 'Postleitzahl',
      'country': 'Land',
      'company': 'Firma',
      'vat_number': 'USt-IdNr.',
      'PL': 'Polen',
      'AT': 'Österreich',
      'BE': 'Belgien',
      'HR': 'Kroatien',
      'DK': 'Dänemark',
      'EE': 'Estland',
      'IT': 'Italien',
      'LV': 'Lettland',
      'LT': 'Litauen',
      'DE': 'Deutschland',
      'SK': 'Slowakei',
      'SI': 'Slowenien',
      'SE': 'Schweden',
      'CH': 'Schweiz',
      'UK': 'Vereinigtes Königreich',
      'NL': 'Niederlande',

      'not_paid': 'Die Bestellung wurde nicht bezahlt.',
      'billing_and_shipping_country_must_be_the_same': 'Rechnungs- und Lieferland müssen identisch sein',
      'thank_you': 'Vielen Dank.',
      'order_received': 'Deine Bestellung ist eingegangen.',
      'order_number': 'Bestellnummer',
      'date': 'Datum',
      'payment_method': 'Zahlungsmethode',
      'order_details': 'Bestelldetails',
      'quantity': 'Menge',
      'total': 'Gesamt',


}