import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
`

const Logo = () => (
  <StyledLink to="/" >
    <svg
      id="Group_10433"
      data-name="Group 10433"
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="16.778"
      viewBox="0 0 100 16.778"
    >
      <path
        id="Path_9918"
        data-name="Path 9918"
        d="M260.814,25.094a4.133,4.133,0,0,0-4.133-4.139h-9.929a4.138,4.138,0,0,0-4.137,4.139h0v8.273h0a4.135,4.135,0,0,0,4.137,4.137h9.929a4.13,4.13,0,0,0,4.133-4.137h0V25.094Zm-14.066,0,.007-.007,9.929.007,0,8.276-9.933,0Z"
        transform="translate(-160.817 -20.953)"
        fill="#2a2954"
      />
      <path
        id="Path_9919"
        data-name="Path 9919"
        d="M210.711,32.157v1.214l-9.934,0V25.1l.007-.007,9.929.007v1.2h4.134v-1.2h0a4.133,4.133,0,0,0-4.133-4.139h-9.93a4.139,4.139,0,0,0-4.137,4.139h0v8.273h0A4.136,4.136,0,0,0,200.78,37.5h9.93a4.13,4.13,0,0,0,4.133-4.137h0V32.157Z"
        transform="translate(-155.89 -20.953)"
        fill="#2a2954"
      />
      <path
        id="Path_9920"
        data-name="Path 9920"
        d="M234.524,20.955H233.7v6.206h-9.939V20.955h-4.135V37.5h4.135V31.3H233.7V37.5h4.133V20.955Z"
        transform="translate(-158.354 -20.953)"
        fill="#2a2954"
      />
      <path
        id="Path_9921"
        data-name="Path 9921"
        d="M191.86,20.953h-4.134l0,5.8-9.933,0V20.953h-4.137v5.794h0a4.135,4.135,0,0,0,4.137,4.137h2.9V37.5h4.135V30.883h2.9a4.13,4.13,0,0,0,4.133-4.137h0V20.953Z"
        transform="translate(-153.426 -20.953)"
        fill="#2a2954"
      />
      <path
        id="Path_9922"
        data-name="Path 9922"
        d="M162.936,36.879v2.056l-4.129-4.689V32.185Z"
        transform="translate(-151.835 -22.156)"
        fill="#2a2954"
      />
      <path
        id="Path_9923"
        data-name="Path 9923"
        d="M162.936,32.263v2.061l-4.129-4.689V27.574Z"
        transform="translate(-151.835 -21.662)"
        fill="#2a2954"
      />
      <path
        id="Path_9924"
        data-name="Path 9924"
        d="M162.534,27.443V29.5L155.055,21h1.81Z"
        transform="translate(-151.433 -20.958)"
        fill="#2a2954"
      />
      <path
        id="Path_9925"
        data-name="Path 9925"
        d="M158.806,36.8l2.117,2.4h-2.117Z"
        transform="translate(-151.835 -22.651)"
        fill="#2a2954"
      />
      <path
        id="Path_9926"
        data-name="Path 9926"
        d="M169.777,25.093V21H159.11l3.6,4.093Z"
        transform="translate(-151.867 -20.958)"
        fill="#2a2954"
      />
      <path
        id="Path_9927"
        data-name="Path 9927"
        d="M152.808,25.362l-1.629-1.851v1.851Z"
        transform="translate(-151.017 -21.227)"
        fill="#2a2954"
      />
      <path
        id="Path_9928"
        data-name="Path 9928"
        d="M156.41,25.093,152.809,21H151l3.6,4.093Z"
        transform="translate(-150.998 -20.958)"
        fill="#2a2954"
      />
    </svg>
  </StyledLink>
)

export default Logo
