import React from 'react'
import styled from 'styled-components'
import Mask from '../../atoms/Mask'

const DrawerWrapper = styled.div`
  position: fixed;
  z-index: ${({ zIndex }) => (zIndex ? zIndex : '100000')};
  height: 100%;
  width: 0;
  ${({ left }) => !left && `right: 0`};
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), height 0s ease 0.3s,
    width 0s ease 0.3s, -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
`

const DrawerC = styled.div`
  ${({ left }) => left && `overflow-y: scroll`};
  position: absolute;
  height: 100%;
  width: 307px;
  ${({ left }) => !left && `right: 0`};
  ${({ left }) => left && `left: 0`};
  ${({ visible, left }) =>
    left
      ? !visible && `transform: translateX(-100%)`
      : !visible && `transform: translateX(100%)`};
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1),
    box-shadow 0.3s cubic-bezier(0.7, 0.3, 0.1, 1),
    -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1),
    -webkit-box-shadow 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
`
const DrawerContent = styled.div`
  height: 100%;
  width: 100%;
  background-color: white;
`

const Drawer = ({ visible, hideDrawer, left, content, zIndex }) => {
  return (
    <>
      <Mask onClick={hideDrawer} visible={visible} />
      <DrawerWrapper left={left} zIndex={zIndex}>
        <DrawerC className="drawer" visible={visible} left={left}>
          <DrawerContent className="drawerContent">
            {content ? content : null}
          </DrawerContent>
        </DrawerC>
      </DrawerWrapper>
    </>
  )
}
export default Drawer
