module.exports = {

      // Static Pages
      'company_page': `
## O firmie
### &nbsp;
Tycho jest producentem rozwiązań przesuwnych i składanych. W swojej ofercie posiada systemy do drzwi składanych i przesuwnych do drewna i materiałów pochodnych, system do drzwi przesuwnych szklanych a także uzupełniające akcesoria. Przedsiębiorstwo sukcesywnie zwiększa gamę produktów, wprowadzając nowe autorskie rozwiązania, na które często posiada zastrzeżenia użytkowe. Wyroby Tycho posiadają najwyższą jakość wykonania, a optymalizacja procesów produkcji, zapewnia również konkurencyjną cenę.
Tycho oferuje produkty odznaczające się łatwością montażu i komfortem użytkowania. Ich trwałość i bezpieczeństwo podkreślone są udzielaną na nie 5-letnią gwarancją.\n
Firma Tycho została założona w 1981 roku. Na początku działalności zajmowała się produkcją dla przemysłu samochodowego. Systemy do drzwi przesuwnych i składanych produkuje od roku 1997.
W 2016 uruchomiła sklep internetowy, zwiększając zasięg bezpośredniej sprzedaży produktów.
      `,
      'shipping_page': `
## Koszt dostawy
### &nbsp;
#### Koszt dostawy na terenie Polski wynosi
#### dla przesyłek o dł. do 250 cm: 13.90 zł brutto,
#### dla przesyłek o dł. od 250 cm: 25.90 zł brutto.

### &nbsp;
##### W przypadku wysyłki za pobraniem doliczamy dodatkowo 3.00 zł.
##### Wysyłka za pobraniem jest możliwa przy zamówieniach o wartości do 2500 zł.`,

      'imprint_page': `
## Imprint
### &nbsp;
#### Tycho Krzysztof Włodarek
#### Właściciel: Krzysztof Marek Włodarek
#### NIP: PL8220001767
#### &nbsp;
#### ul. Kościelna 32E
#### 05-311 Dębe Wielkie
#### Polska
#### &nbsp;
#### Phone: +48 513 589 347
#### E-Mail: <sklep@tycho.pl>
### &nbsp;
### &nbsp;
###### The European Commission provides a platform for online dispute resolutions (ODR) which can be accessed at https&#65279;://ec.europa.eu/consumers/odr/. In order to settle disputes arising from a contractual relationship with a consumer or from whether such a contractual relationship exists at all, we will participate in dispute settlement proceedings before a consumer dispute resolution body. Consumers can contact their national European Consumer Centre in this regard. The respective contact details of the individual ECCs can be found at https&#65279;://www.evz.de/en/alternative-dispute-resolution/adr-in-europe/.`,


      'cancellation_page': `
## Odstąpienie od umowy
#### &nbsp;
#### &nbsp;
**Prawo odstąpienia od umowy**

Mają Państwo prawo odstąpić od niniejszej umowy w terminie 14 dni bez podania jakiejkolwiek przyczyny. Termin do odstąpienia od umowy wygasa po upływie 14 dni od dnia, w którym weszli Państwo w posiadanie rzeczy lub w którym osoba trzecia inna niż przewoźnik i wskazana przez Państwa weszła w posiadanie rzeczy.

Aby skorzystać z prawa odstąpienia od umowy, muszą Państwo poinformować nas (Tycho Krzysztof Włodarek, Kościelna, 32E, 05-311 Dębe Wielkie, sklep@tycho.pl, Telefon: +48 513 589 347) o swojej decyzji o odstąpieniu od niniejszej umowy w drodze jednoznacznego oświadczenia (na przykład pismo wysłane pocztą, faksem lub pocztą elektroniczną). Mogą Państwo skorzystać z wzoru formularza odstąpienia od umowy, jednak nie jest to obowiązkowe.

Aby zachować termin do odstąpienia od umowy, wystarczy, aby wysłali Państwo informację dotyczącą wykonania przysługującego Państwu prawa odstąpienia od umowy przed upływem terminu do odstąpienia od umowy.


#### &nbsp;
**Skutki odstąpienia od umowy**

W przypadku odstąpienia od niniejszej umowy zwracamy Państwu wszystkie otrzymane od Państwa płatności, w tym koszty dostarczenia rzeczy (z wyjątkiem dodatkowych kosztów wynikających z wybranego przez Państwa sposobu dostarczenia innego niż najtańszy zwykły sposób dostarczenia oferowany przez nas), niezwłocznie, a w każdym przypadku nie później niż 14 dni od dnia, w którym zostaliśmy poinformowani o Państwa decyzji o wykonaniu prawa odstąpienia od niniejszej umowy. Zwrotu płatności dokonamy przy użyciu takich samych sposobów płatności, jakie zostały przez Państwa użyte w pierwotnej transakcji, chyba że wyraźnie zgodziliście się Państwo na inne rozwiązanie; w każdym przypadku nie poniosą Państwo żadnych opłat w związku z tym zwrotem. Możemy wstrzymać się ze zwrotem płatności do czasu otrzymania rzeczy lub do czasu dostarczenia nam dowodu jej odesłania, w zależności od tego, które zdarzenie nastąpi wcześniej.

Proszę odesłać lub przekazać nam rzecz na adres Tycho Krzysztof Włodarek, ul. Kościelna 32E, 05-311 Dębe Wielkie, niezwłocznie, a w każdym razie nie póź niej niż 14 dni od dnia, w którym poinformowali nas Państwo o odstąpieniu od niniejszej umowy. Termin jest zachowany, jeżeli odeślą Państwo rzecz przed upływem terminu 14 dni.
Ponosimy koszty zwrotu rzeczy. Odpowiadają Państwo tylko za zmniejszenie wartości rzeczy wynikające z korzystania z niej w sposób inny niż było to konieczne do stwierdzenia charakteru, cech i funkcjonowania rzeczy.

#### &nbsp;
**Wzór formularza odstąpienia od umowy**

*Formularz ten należy wypełnić i odesłać tylko w przypadku chęci odstąpienia od umowy*
##### – Adresat: Tycho Krzysztof Włodarek, ul. Kościelna 32E, 05-311 Dębe Wielkie, Polska, sklep@tycho.pl
##### – Ja/My niniejszym informuję/informujemy o moim/naszym odstąpieniu od umowy sprzedaży następujących rzeczy umowy dostawy następujących rzeczy:
##### – Data odbioru:  
##### – Imię i nazwisko konsumenta(-ów):
##### – Adres konsumenta(-ów):
##### – Data:  
##### – Podpis konsumenta(-ów) (tylko jeżeli formularz jest przesyłany w wersji papierowej):

#### &nbsp;
#### &nbsp;

`,

      'data_protection_page': `
## Polityka prywatności
#### &nbsp;
#### &nbsp;
#### Dokładamy wszelkich starań w celu zapewnienia poszanowania Państwa prywatności i ochrony udzielonych informacji osobowych podczas korzystania z witryny i dokonywania zakupów w ramach sklepu.
#### &nbsp;
#### &nbsp;
Niniejsza Polityka prywatności określa zasady pobierania i gromadzenia oraz przetwarzania i wykorzystywania danych osobowych pozyskanych od Państwa przez Sklep internetowy Tycho, prowadzony pod adresem http://tycho.pl (zwany dalej Witryną) przez Tycho Krzysztof Włodarek z siedzibą pod adresem: Kościelna 32E, 05-311, Dębe Wielkie, (zwany dalej Sklepem Internetowym).

#### &nbsp;
**Jakie dane Sklepu internetowy pobiera w sposób automatyczny podczas korzystania z Witryny?**

Sklep internetowy nie pobiera w sposób automatyczny żadnych danych, z wyjątkiem danych zawartych w plikach, o których mowa poniżej, podczas samego korzystania z Witryny.Pliki cookies to małe pliki tekstowe wysyłane przez Sklep internetowy i przechowywane na Państwa komputerze zawierające pewne informacje związane z korzystaniem przez Państwa z Witryny i Sklepu Internetowego. Wykorzystywane przez Sklep internetowy pliki cookies mogą mieć charakter tymczasowy lub trwały. Tymczasowe pliki cookies są usuwane z chwilą zamknięcia przeglądarki, natomiast stałe pliki cookies są przechowywane także po zakończeniu korzystania przez Państwa z Witryny i służą do przechowywania informacji takich jak Państwa hasło czy login, co przyspiesza i ułatwia korzystanie z Witryny. W ramach Witryny stosowane są następujące rodzaje plików cookies:
 * pliki cookies umożliwiające korzystanie z usług dostępnych w ramach Witryny, np. uwierzytelniające pliki cookies wykorzystywane do usług wymagających uwierzytelniania w ramach Witryny
 * „wydajnościowe” pliki cookies, umożliwiające zbieranie informacji o sposobie korzystania ze stron internetowych Witryny
 * „funkcjonalne” pliki cookies, umożliwiające „zapamiętanie” wybranych przez użytkownika ustawień i personalizację interfejsu użytkownika

Sklep internetowy wykorzystuje wymienione pliki cookies w następujących celach:

* utrzymanie sesji użytkownika Witryny (po zalogowaniu), dzięki której użytkownik nie musi na każdej podstronie Witryny ponownie wpisywać loginu i hasła
* dostosowania zawartości stron internetowych Witryny do preferencji użytkownika oraz optymalizacji korzystania ze stron internetowych; w szczególności pliki te pozwalają rozpoznać urządzenie użytkownika Witryny i odpowiednio wyświetlić stronę internetową, dostosowaną do jego indywidualnych potrzeb
* tworzenia statystyk, które pomagają zrozumieć, w jaki sposób użytkownicy Witryny korzystają ze stron internetowych, co umożliwia ulepszanie ich struktury i zawartości

W wielu przypadkach oprogramowanie służące do przeglądania stron internetowych (przeglądarka internetowa) domyślnie dopuszcza przechowywanie plików cookies w urządzeniu końcowym użytkownika. Możecie Państwo dokonać w każdym czasie zmiany ustawień dotyczących plików cookies. Ustawienia te mogą zostać zmienione w szczególności w taki sposób, aby blokować automatyczną obsługę plików cookies w ustawieniach przeglądarki internetowej bądź informować o ich każdorazowym zamieszczeniu w Państwa urządzeniu. Szczegółowe informacje o możliwości i sposobach obsługi plików cookies dostępne są w ustawieniach oprogramowania (przeglądarki internetowej).
Sklep Internetowy informuje, że ograniczenia stosowania plików cookies mogą wpłynąć na niektóre funkcjonalności dostępne na stronach internetowych Witryny.
Więcej informacji na temat plików cookies dostępnych jest w sekcji „Pomoc” w menu przeglądarki internetowej.

#### &nbsp;
**Jakie dane pobiera Sklep internetowy podczas rejestracji i dokonywania zakupów?**

Sklep internetowy będzie od Państwa pobierał następujące dane osobowe za pośrednictwem Witryny oraz innych form komunikacji, w przypadku dokonywania zakupów i procesu rejestracji na Witrynie:
* nazwisko i imię,
* adres zameldowania na pobyt stały,
* adres do korespondencji, jeżeli jest inny niż adres zameldowania,
* adres poczty elektronicznej,
* numer telefonu.

Podanie powyższych danych jest dobrowolne, lecz konieczne dla dokonania rejestracji i zakupu w ramach Witryny.

Przysługuje Państwu prawo dostępu do treści swoich danych osobowych oraz prawo ich poprawiania. W celu realizacji tego uprawnienia Prosimy o korzystanie z opcji w ramach swojego konta lub z adresu e-mail wskazanego poniżej.

#### &nbsp;
**Udostępnienie informacji**

W celu realizacji umowy Sklep internetowy może udostępniać zebrane od Państwa dane partnerom, wśród nich tym, za pomocą których Sklep realizuje zobowiązania jak np. firmy kurierskie, operatorzy systemów płatności lub firmy obsługujące reklamacje. W takich przypadkach ilość przekazywanych danych ograniczona jest do wymaganego minimum.

Ponadto, podane przez Państwa informacje mogą zostać udostępnione właściwym organom władzy publicznej, jeżeli wymagają tego obowiązujące przepisy prawa.

#### &nbsp;
**Środki techniczne i Państwa obowiązki**

Sklep internetowy dokłada wszelkich możliwych starań, aby zabezpieczyć Państwa dane i ochronić je przed działaniami osób trzecich.

Podjęte przez nas działania mogą okazać się jednak niewystarczające, jeżeli sami nie zachowają Państwo zasad bezpieczeństwa. W szczególności muszą Państwo zachować w poufności login i hasło do Witryny i nie udostępniać ich osobom trzecim. Proszę pamiętać, że Sklep internetowy nie będzie zwracał się do Państwa z prośbą o ich podanie, z wyjątkiem podania ich w trakcie logowania na Witrynie. W celu uniemożliwienia korzystania z Państwa konta osobom nieuprawnionym prosimy się wylogować po zakończeniu korzystania z Witryny.

#### &nbsp;
**W jaki sposób możecie Państwo korzystać z przysługujących praw?**

Macie Państwo możliwość podglądu i edycji Państwa danych, w każdym czasie, w ramach Witryny, po zalogowaniu się z użyciem adresu e-mail oraz hasła. W sytuacji, gdy zapomnicie Państwo hasła lub wystąpienia innych problemów z logowaniem, prosimy o kontakt pod adresem e-mail wskazanym poniżej.

Przysługuje Państwu prawo do żądania informacji o treści przechowywanych na Państwa temat danych, jak również prawo do żądania zmiany, zablokowania lub usunięcia danych, a także prawo do sprostowania błędów, uzupełnienia lub uaktualnienia Państwa danych. Macie Państwo również możliwość wniesienia sprzeciwu wobec przetwarzania Państwa danych osobowych w celach marketingowych. Prosimy w tym celu o kontakt pod adresem e-mail wskazanym poniżej.

#### &nbsp;
**Funkcja „Oceń później”**

Istnieje możliwość ocenienia Sklepu internetowego po złożonym zamówieniu za pomocą funkcji „oceń później” poprzez wyrażenie zgody o następującej treści: „Tak, chciał(a)bym skorzystać z opcji „Oceń później”. Proszę Trusted Shops o przypomnienie mi o możliwości dodania opinii za pośrednictwem wiadomości e-mail, która zostanie mi wysłana po kilku dniach”.
Jeśli otrzymana zostanie powyższa, wyraźna zgoda na przesłanie przypomnienia, Państwa adres e-mail zostanie za pośrednictwem systemu opinii Trusted Shops przekazany firmie Trusted Shops GmbH z siedzibą przy Subbelrather Str 15c, 50823 Kolonia (www.trustedshops.pl). Celem przekazania adresu jest wysłanie po upływie określonego czasu przypomnienia o możliwości dodania opinii o usłudze. Niniejsza zgoda może być w każdej chwili odwołana ze skutkiem na przyszłość (np. poprzez wysłanie wiadomości na email: serwis@trustedshops.pl).

#### &nbsp;
**Inne strony internetowe**

W ramach Witryny mogą pojawiać się okresowo linki do innych stron internetowych. Takie strony internetowe działają niezależnie od Witryny i nie są w żaden sposób nadzorowane przez Sklep internetowy. Strony te mogą posiadać własne polityki dotyczące prywatności, z którymi zalecamy zapoznać się. Sklep internetowy nie ponosi odpowiedzialności za zasady postępowania z danymi w ramach tych stron.

#### &nbsp;
**Pytania i zastrzeżenia**

Pytania i zastrzeżenia dotyczące niniejszej Polityki prywatności mogą być przez Państwa kierowane do: sklep@tycho.pl


 
`,


}