// Do not edit directly
// File generated from gatsby-node.js

export default {
      '/nl/producten/pura': '/de/produkte/pura',
      '/nl/producten/verbindingsbeugel-25-uni': '/de/produkte/verbindungswinkel-25-uni',
      '/nl/producten/houten-lateihaak-uni': '/de/produkte/holzblende-montagewinkel-uni',
      '/nl/producten/muurbevestigingssteun-25-uni': '/de/produkte/montagewinkel-25-uni',
      '/nl/producten/aluminium-rail-uni': '/de/produkte/alu-laufschiene-uni',
      '/nl/producten/geanodiseerde-aluminium-latei-met-eindkappen-45-mm-zwart': '/de/produkte/eloxierte-aluminiumblende-mit-kappen-45-mm-schwarz',
      '/nl/producten/geanodiseerde-aluminium-latei-met-eindkappen-25-mm-zwart': '/de/produkte/eloxierte-aluminiumblende-mit-kappen-25-mm-schwarz',
      '/nl/producten/plat-aluminium-latei-zwart': '/de/produkte/flache-aluminium-blende-schwarz',
      '/nl/producten/handgreep-58-mm-zwart': '/de/produkte/griff-58-mm-schwarz',
      '/nl/producten/ondergeleider-voor-systemen-g80-g100': '/de/produkte/untere-fuhrung-fur-systeme-g80-g100',
      '/nl/producten/zachte-sluitdemper-voor-s120-g100': '/de/produkte/turschliessdampfer-s120-g100',
      '/nl/producten/zachte-sluitdemper-voor-s60-s100': '/de/produkte/turschliessdampfer-s60-s100',
      '/nl/producten/loft-t-zwart-schuifdeursysteem': '/de/produkte/loft-schiebesystem-variante-t-schwarz',
      '/nl/producten/w40-sliding-system-kit-for-2-additional-door-leaves': '/de/produkte/w40-schiebetur-kit-fur-2-zusatzliche-turen',
      '/nl/producten/w40-sliding-system-kit-for-1-additional-door-leaf': '/de/produkte/w40-schiebetur-kit-fur-1-zusatzliche-tur',
      '/nl/producten/l30-sliding-system-kit-for-additional-door-leaf': '/de/produkte/l30-schiebetur-kit-fur-zusatzliche-tur',
      '/nl/producten/l40-sliding-system-kit-for-additional-door-leaf': '/de/produkte/l40-schiebetur-kit-fur-zusatzliche-tur',
      '/nl/producten/soft-close-damper-w40': '/de/produkte/turschliessdampfer-w40',
      '/nl/producten/w60-sliding-system-kit-for-2-additional-door-leaves': '/de/produkte/w60-schiebetur-kit-fur-2-zusatzliche-turen',
      '/nl/producten/w60-sliding-system-kit-for-1-additional-door-leaf': '/de/produkte/w60-schiebetur-kit-fur-1-zusatzliche-tur',
      '/nl/producten/l30-system-for-lightweight-sliding-doors': '/de/produkte/l30-system-fur-leichte-schiebeturen',
      '/nl/producten/l40-system-for-lightweight-sliding-doors': '/de/produkte/l40-system-fur-leichte-schiebeturen',
      '/nl/producten/loft-karo-zwart-schuifdeursysteem': '/de/produkte/loft-schiebesystem-variante-karo-schwarz',
      '/nl/producten/loft-u-zwart-schuifdeursysteem': '/de/produkte/loft-schiebesystem-variante-u-schwarz',
      '/nl/producten/ondergeleider-voor-systemen-s60-s100-s120': '/de/produkte/untere-fuhrung-fur-systeme-s60-s100-s120',
      '/nl/producten/plat-aluminium-latei': '/de/produkte/flache-aluminium-blende',
      '/nl/producten/afstandshuls': '/de/produkte/abstandshalter',
      '/nl/producten/w60-schuifsysteem-voor-kast-kleerkastdeuren': '/de/produkte/w60-schiebetursystem-fur-schrankturen-nischen',
      '/nl/producten/w40-schuifsysteem-voor-kast-kleerkastdeuren': '/de/produkte/w40-schiebetursystem-fur-schrankturen-nischen',
      '/nl/producten/handgreep-schuifdeur-03': '/de/produkte/turgriff-03',
      '/nl/producten/loft-zachte-sluitdempers-wit': '/de/produkte/loft-turschliessdampfer-weiss',
      '/nl/producten/loft-zachte-sluitdempers-zwart': '/de/produkte/loft-turschliessdampfer-schwarz',
      '/nl/producten/loft-v-zwart-schuifdeursysteem': '/de/produkte/loft-schiebesystem-variante-v-schwarz',
      '/nl/producten/f40-roller': '/de/produkte/f40-laufwagen',
      '/nl/producten/f40-vouwdeursysteemkit-voor-2-extra-panelen': '/de/produkte/f40-falttur-kit-fur-2-zusatzliche-paneele',
      '/nl/producten/f25-roller': '/de/produkte/f25-laufwagen',
      '/nl/producten/f25-vouwdeursysteemkit-voor-2-extra-panelen': '/de/produkte/f25-falttur-kit-fur-2-zusatzliche-paneele',
      '/nl/producten/g100-schuifdeursysteemkit-voor-extra-glazen-deuren': '/de/produkte/g100-schiebetur-kit-fur-zusatzliche-glastur',
      '/nl/producten/g80-schuifdeursysteemkit-voor-extra-glazen-deuren': '/de/produkte/g80-schiebetur-kit-fur-zusatzliche-glastur',
      '/nl/producten/geanodiseerde-aluminium-latei-met-eindkappen-45-mm': '/de/produkte/eloxierte-aluminiumblende-mit-kappen-45-mm',
      '/nl/producten/geanodiseerde-aluminium-latei-met-eindkappen-25-mm': '/de/produkte/eloxierte-aluminiumblende-mit-kappen-25-mm',
      '/nl/producten/aluminium-rail-s': '/de/produkte/alu-laufschiene-s',
      '/nl/producten/handgreep-58-mm': '/de/produkte/griff-58-mm',
      '/nl/producten/loft-i-xl-zwart-schuifdeursysteem': '/de/produkte/loft-schiebesystem-variante-i-xl-schwarz',
      '/nl/producten/loft-i-zwart-schuifdeursysteem': '/de/produkte/loft-schiebesystem-variante-i-schwarz',
      '/nl/producten/g80-schuifdeursysteem-voor-glazen-deuren': '/de/produkte/g80-schiebetursystem-fur-glasturen',
      '/nl/producten/g100-schuifdeursysteem-voor-glazen-deuren': '/de/produkte/g100-schiebetursystem-fur-glasturen',
      '/nl/producten/f40-vouwdeursysteem-voor-twee-panelen': '/de/produkte/f40-falttursystem-fur-2-paneele',
      '/nl/producten/f25-vouwdeursysteem-voor-twee-panelen': '/de/produkte/f25-falttursystem-fur-2-paneele',
      '/nl/producten/s100-schuifdeursysteem-voor-twee-deuren': '/de/produkte/s100-schiebetursystem-fur-2-turen',
      '/nl/producten/s120-schuifdeursysteem-voor-twee-deuren': '/de/produkte/s120-schiebetursystem-fur-2-turen',
      '/nl/producten/s60-schuifdeursysteem-voor-twee-deuren': '/de/produkte/s60-schiebetursystem-fur-2-turen',
      '/nl/producten/s120-roller': '/de/produkte/s120-laufwagen',
      '/nl/producten/s100-roller': '/de/produkte/s100-laufwagen',
      '/nl/producten/s60-roller': '/de/produkte/s60-laufwagen',
      '/nl/producten/houten-lateihaak-s': '/de/produkte/holzblende-montagewinkel-s',
      '/nl/producten/verbindingsbeugel-45-s': '/de/produkte/verbindungswinkel-45-s',
      '/nl/producten/verbindingsbeugel-25-s': '/de/produkte/verbindungswinkel-25-s',
      '/nl/producten/muurbevestigingssteun-45-s': '/de/produkte/montagewinkel-45-s',
      '/nl/producten/muurbevestigingssteun-25-s': '/de/produkte/montagewinkel-25-s',
      '/nl/producten/s100-schuifdeursysteemkit-voor-extra-deuren': '/de/produkte/s100-schiebetur-kit-fur-zusatzliche-tur',
      '/nl/producten/s120-schuifdeursysteemkit-voor-extra-deuren': '/de/produkte/s120-schiebetur-kit-fur-zusatzliche-tur',
      '/nl/producten/s60-schuifdeursysteemkit-voor-extra-deuren': '/de/produkte/s60-schiebetur-kit-fur-zusatzliche-tur',
      '/nl/producten/2s-synchronisatiekit': '/de/produkte/2s-synchronisationsbausatz',
      '/nl/producten/s100-schuifdeursysteem-voor-een-deur': '/de/produkte/s100-schiebetursystem-fur-1-tur',
      '/nl/producten/s120-schuifdeursysteem-voor-een-deur': '/de/produkte/s120-schiebetursystem-fur-1-tur',
      '/nl/producten/s60-schuifdeursysteem-voor-een-deur': '/de/produkte/s60-schiebetursystem-fur-1-tur',
      '/nl/imprint': '/de/imprint',
      '/nl/verzending-en-betaling': '/de/versand-und-zahlung',
      '/nl/data-protection': '/de/datenschutz',
      '/nl/cancellation': '/de/widerrufsbelehrung',
      '/nl/bedrijf': '/de/unternehmen',
      '/nl/terms-and-conditions': '/de/agb',
      '/nl/producten': '/de/produkte',
}
