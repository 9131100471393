import React from 'react'
import styled from 'styled-components'

const Button = styled.div`
  border: none;
  background-color: white;
  cursor: pointer;
  margin-top: 5px;
`

const DeleteBtn = ({ onClick }) => {
  return (
    <Button onClick={onClick}>
      <svg width="14" height="18" viewBox="0 0 14 18">
        <path
          d="M6,19a2.006,2.006,0,0,0,2,2h8a2.006,2.006,0,0,0,2-2V9a2.006,2.006,0,0,0-2-2H8A2.006,2.006,0,0,0,6,9ZM18,4H15.5l-.71-.71a1.009,1.009,0,0,0-.7-.29H9.91a1.009,1.009,0,0,0-.7.29L8.5,4H6A1,1,0,0,0,6,6H18a1,1,0,0,0,0-2Z"
          transform="translate(-5 -3)"
          fill="#a6a6a6"
        />
      </svg>
    </Button>
  )
}

export default DeleteBtn
