import React, { useContext } from 'react'
import styled from 'styled-components'
import { UsersContext } from '../context'
import { inactiveGray } from '../utils/theme'

const CartIconWrapper = styled.div`
  position: relative;
  cursor: pointer;
  text-align: right;
  margin-right: 12px;
  
`
const CartTotal = styled.div`
  right: -12px;
  top: -4px;
  position: absolute;
  z-index: 99;
  line-height: 13px;

`
const CartTotal2 = styled.div`
  position: absolute;
  color: white;
  font-size: 9px;
  margin: 2px auto;
  text-align: center;
  width: 17px;
`
const Circle = styled.div`
  border-radius: 50%;
  width: 17px;
  height: 17px;
  background-color: #2a2954;
`

const Button = styled.button`
  cursor: pointer;
  padding: 0;
  border: 0;
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;

  &:disabled {
    cursor: not-allowed;
    ${Circle} {
      background-color: ${inactiveGray};
    }
    svg * {
       fill: ${inactiveGray};
    }
    * {
      cursor: not-allowed;
    }
  }
`

const CartIcon = ({ showDrawer, disabled }) => {
  const { cart } = useContext(UsersContext)

  return (
    <Button
      disabled={disabled}
      onClick={showDrawer}

    >
      <CartIconWrapper>
        <CartTotal>
          <CartTotal2> {cart.length} </CartTotal2>
          <Circle />
        </CartTotal>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16.326"
          height="15.75"
          viewBox="0 0 16.326 15.75"
        >
          <g
            id="Group_10724"
            data-name="Group 10724"
            transform="translate(-4617 -4923.125)"
          >
            <circle
              id="Ellipse_21"
              data-name="Ellipse 21"
              cx="1.125"
              cy="1.125"
              r="1.125"
              transform="translate(4621.5 4936.625)"
              fill="#2a2954"
            />
            <circle
              id="Ellipse_22"
              data-name="Ellipse 22"
              cx="1.125"
              cy="1.125"
              r="1.125"
              transform="translate(4629.375 4936.625)"
              fill="#2a2954"
            />
            <path
              id="Path_10312"
              data-name="Path 10312"
              d="M15.75,4.812H3.274L2.813,2.45A.562.562,0,0,0,2.25,2H0V3.125H1.789l2.149,10.8a.562.562,0,0,0,.562.45H14.625V13.25H4.961L4.5,11H14.625a.562.562,0,0,0,.563-.439L16.313,5.5a.562.562,0,0,0-.562-.686ZM14.175,9.875H4.286L3.5,5.937H15.047Z"
              transform="translate(4617 4921.125)"
              fill="#2a2954"
            />
          </g>
        </svg>
      </CartIconWrapper>
    </Button>
  )
}

export default CartIcon
