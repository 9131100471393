import React from "react"
import { Helmet } from "react-helmet"
import { germany } from '../initial/vars'


const TrustBadge = () => (
  <Helmet>
    { germany ?
    <script type="text/javascript">
    {`
        (function () { 
          var _tsid = 'X962700EF0DAFC87A790FD93EAC604289'; 
          _tsConfig = { 
            'yOffset': '-30', /* offset from page bottom */
            'variant': 'reviews', /* default, reviews, custom, custom_reviews */
            'customElementId': '', /* required for variants custom and custom_reviews */
            'trustcardDirection': '', /* for custom variants: topRight, topLeft, bottomRight, bottomLeft */
            'customBadgeWidth': '', /* for custom variants: 40 - 90 (in pixels) */
            'customBadgeHeight': '', /* for custom variants: 40 - 90 (in pixels) */
            'disableResponsive': 'false', /* deactivate responsive behaviour */
            'disableTrustbadge': 'false' /* deactivate trustbadge */
          };
          var _ts = document.createElement('script');
          _ts.type = 'text/javascript'; 
          _ts.charset = 'utf-8'; 
          _ts.async = true; 
          _ts.src = '//widgets.trustedshops.com/js/' + _tsid + '.js'; 
          var __ts = document.getElementsByTagName('script')[0];
          __ts.parentNode.insertBefore(_ts, __ts);
        })();
        `}
    </script>
    :
    <script type="text/javascript">
    {`
        (function () { 
          var _tsid = 'XE0B9380D05B4F1848CB3DA1343E3E59E'; 
          _tsConfig = { 
            'yOffset': '-30', /* offset from page bottom */
            'variant': 'reviews', /* default, reviews, custom, custom_reviews */
            'customElementId': '', /* required for variants custom and custom_reviews */
            'trustcardDirection': '', /* for custom variants: topRight, topLeft, bottomRight, bottomLeft */
            'customBadgeWidth': '', /* for custom variants: 40 - 90 (in pixels) */
            'customBadgeHeight': '', /* for custom variants: 40 - 90 (in pixels) */
            'disableResponsive': 'false', /* deactivate responsive behaviour */
            'disableTrustbadge': 'false' /* deactivate trustbadge */
          };
          var _ts = document.createElement('script');
          _ts.type = 'text/javascript'; 
          _ts.charset = 'utf-8'; 
          _ts.async = true; 
          _ts.src = '//widgets.trustedshops.com/js/' + _tsid + '.js'; 
          var __ts = document.getElementsByTagName('script')[0];
          __ts.parentNode.insertBefore(_ts, __ts);
        })();
      `}
    </script>

    }
  </Helmet>
)


export default TrustBadge