// Do not edit directly
// File generated from gatsby-node.js

export default {
      '/de/produkte/pura': '/de/produkte/pura',
      '/de/produkte/verbindungswinkel-25-uni': '/de/produkte/verbindungswinkel-25-uni',
      '/de/produkte/holzblende-montagewinkel-uni': '/de/produkte/holzblende-montagewinkel-uni',
      '/de/produkte/montagewinkel-25-uni': '/de/produkte/montagewinkel-25-uni',
      '/de/produkte/alu-laufschiene-uni': '/de/produkte/alu-laufschiene-uni',
      '/de/produkte/eloxierte-aluminiumblende-mit-kappen-45-mm-schwarz': '/de/produkte/eloxierte-aluminiumblende-mit-kappen-45-mm-schwarz',
      '/de/produkte/eloxierte-aluminiumblende-mit-kappen-25-mm-schwarz': '/de/produkte/eloxierte-aluminiumblende-mit-kappen-25-mm-schwarz',
      '/de/produkte/flache-aluminium-blende-schwarz': '/de/produkte/flache-aluminium-blende-schwarz',
      '/de/produkte/griff-58-mm-schwarz': '/de/produkte/griff-58-mm-schwarz',
      '/de/produkte/untere-fuhrung-fur-systeme-g80-g100': '/de/produkte/untere-fuhrung-fur-systeme-g80-g100',
      '/de/produkte/turschliessdampfer-s120-g100': '/de/produkte/turschliessdampfer-s120-g100',
      '/de/produkte/turschliessdampfer-s60-s100': '/de/produkte/turschliessdampfer-s60-s100',
      '/de/produkte/loft-schiebesystem-variante-t-schwarz': '/de/produkte/loft-schiebesystem-variante-t-schwarz',
      '/de/produkte/w40-schiebetur-kit-fur-2-zusatzliche-turen': '/de/produkte/w40-schiebetur-kit-fur-2-zusatzliche-turen',
      '/de/produkte/w40-schiebetur-kit-fur-1-zusatzliche-tur': '/de/produkte/w40-schiebetur-kit-fur-1-zusatzliche-tur',
      '/de/produkte/l30-schiebetur-kit-fur-zusatzliche-tur': '/de/produkte/l30-schiebetur-kit-fur-zusatzliche-tur',
      '/de/produkte/l40-schiebetur-kit-fur-zusatzliche-tur': '/de/produkte/l40-schiebetur-kit-fur-zusatzliche-tur',
      '/de/produkte/turschliessdampfer-w40': '/de/produkte/turschliessdampfer-w40',
      '/de/produkte/w60-schiebetur-kit-fur-2-zusatzliche-turen': '/de/produkte/w60-schiebetur-kit-fur-2-zusatzliche-turen',
      '/de/produkte/w60-schiebetur-kit-fur-1-zusatzliche-tur': '/de/produkte/w60-schiebetur-kit-fur-1-zusatzliche-tur',
      '/de/produkte/l30-system-fur-leichte-schiebeturen': '/de/produkte/l30-system-fur-leichte-schiebeturen',
      '/de/produkte/l40-system-fur-leichte-schiebeturen': '/de/produkte/l40-system-fur-leichte-schiebeturen',
      '/de/produkte/loft-schiebesystem-variante-karo-schwarz': '/de/produkte/loft-schiebesystem-variante-karo-schwarz',
      '/de/produkte/loft-schiebesystem-variante-u-schwarz': '/de/produkte/loft-schiebesystem-variante-u-schwarz',
      '/de/produkte/untere-fuhrung-fur-systeme-s60-s100-s120': '/de/produkte/untere-fuhrung-fur-systeme-s60-s100-s120',
      '/de/produkte/flache-aluminium-blende': '/de/produkte/flache-aluminium-blende',
      '/de/produkte/abstandshalter': '/de/produkte/abstandshalter',
      '/de/produkte/w60-schiebetursystem-fur-schrankturen-nischen': '/de/produkte/w60-schiebetursystem-fur-schrankturen-nischen',
      '/de/produkte/w40-schiebetursystem-fur-schrankturen-nischen': '/de/produkte/w40-schiebetursystem-fur-schrankturen-nischen',
      '/de/produkte/turgriff-03': '/de/produkte/turgriff-03',
      '/de/produkte/loft-turschliessdampfer-weiss': '/de/produkte/loft-turschliessdampfer-weiss',
      '/de/produkte/loft-turschliessdampfer-schwarz': '/de/produkte/loft-turschliessdampfer-schwarz',
      '/de/produkte/loft-schiebesystem-variante-v-schwarz': '/de/produkte/loft-schiebesystem-variante-v-schwarz',
      '/de/produkte/f40-laufwagen': '/de/produkte/f40-laufwagen',
      '/de/produkte/f40-falttur-kit-fur-2-zusatzliche-paneele': '/de/produkte/f40-falttur-kit-fur-2-zusatzliche-paneele',
      '/de/produkte/f25-laufwagen': '/de/produkte/f25-laufwagen',
      '/de/produkte/f25-falttur-kit-fur-2-zusatzliche-paneele': '/de/produkte/f25-falttur-kit-fur-2-zusatzliche-paneele',
      '/de/produkte/g100-schiebetur-kit-fur-zusatzliche-glastur': '/de/produkte/g100-schiebetur-kit-fur-zusatzliche-glastur',
      '/de/produkte/g80-schiebetur-kit-fur-zusatzliche-glastur': '/de/produkte/g80-schiebetur-kit-fur-zusatzliche-glastur',
      '/de/produkte/eloxierte-aluminiumblende-mit-kappen-45-mm': '/de/produkte/eloxierte-aluminiumblende-mit-kappen-45-mm',
      '/de/produkte/eloxierte-aluminiumblende-mit-kappen-25-mm': '/de/produkte/eloxierte-aluminiumblende-mit-kappen-25-mm',
      '/de/produkte/alu-laufschiene-s': '/de/produkte/alu-laufschiene-s',
      '/de/produkte/griff-58-mm': '/de/produkte/griff-58-mm',
      '/de/produkte/loft-schiebesystem-variante-i-xl-schwarz': '/de/produkte/loft-schiebesystem-variante-i-xl-schwarz',
      '/de/produkte/loft-schiebesystem-variante-i-schwarz': '/de/produkte/loft-schiebesystem-variante-i-schwarz',
      '/de/produkte/g80-schiebetursystem-fur-glasturen': '/de/produkte/g80-schiebetursystem-fur-glasturen',
      '/de/produkte/g100-schiebetursystem-fur-glasturen': '/de/produkte/g100-schiebetursystem-fur-glasturen',
      '/de/produkte/f40-falttursystem-fur-2-paneele': '/de/produkte/f40-falttursystem-fur-2-paneele',
      '/de/produkte/f25-falttursystem-fur-2-paneele': '/de/produkte/f25-falttursystem-fur-2-paneele',
      '/de/produkte/s100-schiebetursystem-fur-2-turen': '/de/produkte/s100-schiebetursystem-fur-2-turen',
      '/de/produkte/s120-schiebetursystem-fur-2-turen': '/de/produkte/s120-schiebetursystem-fur-2-turen',
      '/de/produkte/s60-schiebetursystem-fur-2-turen': '/de/produkte/s60-schiebetursystem-fur-2-turen',
      '/de/produkte/s120-laufwagen': '/de/produkte/s120-laufwagen',
      '/de/produkte/s100-laufwagen': '/de/produkte/s100-laufwagen',
      '/de/produkte/s60-laufwagen': '/de/produkte/s60-laufwagen',
      '/de/produkte/holzblende-montagewinkel-s': '/de/produkte/holzblende-montagewinkel-s',
      '/de/produkte/verbindungswinkel-45-s': '/de/produkte/verbindungswinkel-45-s',
      '/de/produkte/verbindungswinkel-25-s': '/de/produkte/verbindungswinkel-25-s',
      '/de/produkte/montagewinkel-45-s': '/de/produkte/montagewinkel-45-s',
      '/de/produkte/montagewinkel-25-s': '/de/produkte/montagewinkel-25-s',
      '/de/produkte/s100-schiebetur-kit-fur-zusatzliche-tur': '/de/produkte/s100-schiebetur-kit-fur-zusatzliche-tur',
      '/de/produkte/s120-schiebetur-kit-fur-zusatzliche-tur': '/de/produkte/s120-schiebetur-kit-fur-zusatzliche-tur',
      '/de/produkte/s60-schiebetur-kit-fur-zusatzliche-tur': '/de/produkte/s60-schiebetur-kit-fur-zusatzliche-tur',
      '/de/produkte/2s-synchronisationsbausatz': '/de/produkte/2s-synchronisationsbausatz',
      '/de/produkte/s100-schiebetursystem-fur-1-tur': '/de/produkte/s100-schiebetursystem-fur-1-tur',
      '/de/produkte/s120-schiebetursystem-fur-1-tur': '/de/produkte/s120-schiebetursystem-fur-1-tur',
      '/de/produkte/s60-schiebetursystem-fur-1-tur': '/de/produkte/s60-schiebetursystem-fur-1-tur',
      '/de/imprint': '/de/imprint',
      '/de/versand-und-zahlung': '/de/versand-und-zahlung',
      '/de/datenschutz': '/de/datenschutz',
      '/de/widerrufsbelehrung': '/de/widerrufsbelehrung',
      '/de/unternehmen': '/de/unternehmen',
      '/de/agb': '/de/agb',
      '/de/produkte': '/de/produkte',
}
