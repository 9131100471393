module.exports = {
      'Prowadnik dolny S60, S100, S120': 'Untere Führung für Systeme S60, S100, S120',
      'Prowadnik dolny G80, G100': 'Untere Führung für Systeme G80, G100',
      'Maskownica aluminiowa płaska': 'Flache Aluminium-Blende',
      'Maskownica aluminiowa płaska czarna': 'Flache Aluminium-Blende, schwarz',
      'Tuleja dystansowa': 'Abstandshalter',
      'Domykacz do drzwi przesuwnych S120, G100': 'Türschließdämpfer S120, G100',
      'W60 System do przesuwnych drzwi w szafach i zabudowach wnęk': 'W60 Schiebetürsystem für Schranktüren / Nischen',
      'W40 System do przesuwnych drzwi w szafach i zabudowach wnęk': 'W40 Schiebetürsystem für Schranktüren / Nischen',
      'Uchwyt do drzwi przesuwnych 03': 'Türgriff 03',
      'Uchwyt do drzwi przesuwnych 02': 'Türgriff 02',
      'Uchwyt do drzwi przesuwnych 01': 'Türgriff 01',
      'Domykacze do systemów Loft białych': 'Loft-Türschließdämpfer Weiß',
      'Domykacze do systemów Loft czarnych': 'Loft-Türschließdämpfer Schwarz',
      'System do drzwi przesuwnych Loft I czarny': 'Loft-Schiebesystem Variante I Schwarz',
      'System do drzwi przesuwnych Loft T czarny': 'Loft-Schiebesystem Variante T Schwarz',
      'System do drzwi przesuwnych Loft V czarny': 'Loft-Schiebesystem Variante V Schwarz',
      'System do drzwi przesuwnych Loft U czarny': 'Loft-Schiebesystem Variante U Schwarz',
      'System do drzwi przesuwnych Loft I-XL czarny': 'Loft-Schiebesystem Variante I-XL Schwarz',
      'System do drzwi przesuwnych Loft KARO czarny': 'Loft-Schiebesystem Variante KARO Schwarz',
      'Wózek do systemu F40': 'F40 Laufwagen',
      'F40 System bez prowadnicy na dodatkową parę drzwi': 'F40 Falttür-Kit für 2 zusätzliche Paneele',
      'Wózek do systemu F25': 'F25 Laufwagen',
      'F25 System bez prowadnicy na dodatkową parę drzwi': 'F25 Falttür-Kit für 2 zusätzliche Paneele',
      'G100 System do drzwi przesuwnych szklanych bez prowadnicy': 'G100 Schiebetür-Kit für zusätzliche Glastür',
      'Zestaw G80 bez prowadnicy': 'G80 Schiebetür-Kit für zusätzliche Glastür',
      'Maskownica aluminiowa z zaślepkami bocznymi 45 mm': 'Eloxierte Aluminiumblende mit Kappen 45 mm',
      'Maskownica aluminiowa z zaślepkami bocznymi 45 mm czarna': 'Eloxierte Aluminiumblende mit Kappen 45 mm, schwarz',
      'Maskownica aluminiowa z zaślepkami bocznymi 25 mm': 'Eloxierte Aluminiumblende mit Kappen 25 mm',
      'Maskownica aluminiowa z zaślepkami bocznymi 25 mm czarna': 'Eloxierte Aluminiumblende mit Kappen 25 mm, schwarz',
      
      'Uchwyt ⌀ 58 mm': 'Griff ⌀ 58 mm',
      'Uchwyt ⌀ 58 mm czarny': 'Griff ⌀ 58 mm schwarz',
      'G80 System do drzwi przesuwnych szklanych na 1 skrzydło drzwi': 'G80 Schiebetürsystem für Glastüren',
      'G100 System do drzwi przesuwnych szklanych na 1 skrzydło drzwi': 'G100 Schiebetürsystem für Glastüren',
      'F40 System do drzwi składanych na 1 parę drzwi': 'F40 Falttürsystem für 2 Paneele',
      'F25 System do drzwi składanych na 1 parę drzwi': 'F25 Falttürsystem für 2 Paneele',
      'S100 System do drzwi przesuwnych na 2 skrzydła drzwi': 'S100 Schiebetürsystem für 2 Türen',
      'S120 System do drzwi przesuwnych na 2 skrzydła drzwi': 'S120 Schiebetürsystem für 2 Türen',
      'S60 System do drzwi przesuwnych na 2 skrzydła drzwi': 'S60 Schiebetürsystem für 2 Türen',
      'Wózek do systemu S120': 'S120 Laufwagen',
      'Wózek do systemu S100': 'S100 Laufwagen',
      'Wózek do systemu S60': 'S60 Laufwagen',
      
      'Prowadnica aluminiowa UNI': 'Alu-Laufschiene\u00A0UNI',
      'Prowadnica aluminiowa B': 'Alu-Laufschiene\u00A0B',
      'Prowadnica aluminiowa F': 'Alu-Laufschiene\u00A0F',
      'Prowadnica aluminiowa S': 'Alu-Laufschiene\u00A0S',
      'Uchwyt maskownicy drewnianej F': 'Holzblende-Montagewinkel\u00A0F',
      'Uchwyt maskownicy drewnianej B': 'Holzblende-Montagewinkel\u00A0B',
      'Uchwyt maskownicy drewnianej UNI': 'Holzblende-Montagewinkel\u00A0UNI',
      'Uchwyt maskownicy drewnianej S': 'Holzblende-Montagewinkel\u00A0S',
      'Klamra ścienna łącząca prowadnice 25 UNI': 'Verbindungswinkel 25\u00A0UNI',
      'Klamra ścienna łącząca prowadnice 25 B': 'Verbindungswinkel 25\u00A0B',
      'Klamra ścienna łącząca prowadnice 25 S': 'Verbindungswinkel 25\u00A0S',
      'Klamra ścienna łącząca prowadnice 45 S': 'Verbindungswinkel 45\u00A0S',
      'Klamra ścienna 25 UNI': 'Montagewinkel 25\u00A0UNI',
      'Klamra ścienna 25 B': 'Montagewinkel 25\u00A0B',
      'Klamra ścienna 25 S': 'Montagewinkel 25\u00A0S',
      'Klamra ścienna 45 S': 'Montagewinkel 45\u00A0S',

      'S100 System bez prowadnicy na dodatkowe skrzydło drzwi': 'S100 Schiebetür-Kit für zusätzliche Tür',
      'S120 System bez prowadnicy na dodatkowe skrzydło drzwi': 'S120 Schiebetür-Kit für zusätzliche Tür',
      'S60 System bez prowadnicy na dodatkowe skrzydło drzwi': 'S60 Schiebetür-Kit für zusätzliche Tür',
      '2S Mechanizm synchronicznego otwierania i zamykania drzwi': '2S Synchronisationsbausatz',
      'Domykacz do drzwi przesuwnych S60, S100': 'Türschließdämpfer S60, S100',
      'S100 System do drzwi przesuwnych na 1 skrzydło drzwi': 'S100 Schiebetürsystem für 1 Tür',
      'S120 System do drzwi przesuwnych na 1 skrzydło drzwi': 'S120 Schiebetürsystem für 1 Tür',
      'S60 System do drzwi przesuwnych na 1 skrzydło drzwi': 'S60 Schiebetürsystem für 1 Tür',
      'Domykacz do systemu W40': 'Türschließdämpfer W40',
      'W40 System do przesuwnych drzwi w szafach bez prowadnicy na 2 dodatkowe skrzydła drzwi': 'W40 Schiebetür-Kit für 2 zusätzliche Türen',
      'W40 System do przesuwnych drzwi w szafach bez prowadnicy na 1 dodatkowe skrzydło drzwi': 'W40 Schiebetür-Kit für 1 zusätzliche Tür',
      'W60 System do przesuwnych drzwi w szafach bez prowadnicy na 2 dodatkowe skrzydła drzwi': 'W60 Schiebetür-Kit für 2 zusätzliche Türen',
      'W60 System do przesuwnych drzwi w szafach bez prowadnicy na 1 dodatkowe skrzydło drzwi': 'W60 Schiebetür-Kit für 1 zusätzliche Tür',
      'L30 System do lekkich drzwi przesuwnych': 'L30 System für leichte Schiebetüren',
      'L40 System do lekkich drzwi przesuwnych': 'L40 System für leichte Schiebetüren',
      'L30 System do lekkich drzwi przesuwnych bez prowadnicy na dodatkowe skrzydło drzwi': 'L30 Schiebetür-Kit für zusätzliche Tür',
      'L40 System do lekkich drzwi przesuwnych bez prowadnicy na dodatkowe skrzydło drzwi': 'L40 Schiebetür-Kit für zusätzliche Tür',

      'O firmie': 'Unternehmen',
      'Produkty': 'Produkte',
      'Regulamin': 'AGB',
      'Odstąpienie od umowy': 'Widerrufsbelehrung',
      'Polityka prywatności': 'Datenschutz',
      'Koszty dostawy': 'Versand und Zahlung',
      'Imprint': 'Imprint',


}
