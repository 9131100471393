module.exports = {
    // Static Pages
    'company_page': `
## Company
### &nbsp;
Tycho manufactures sliding and folding systems. Its range includes folding door and sliding door systems for wood and wood-like materials, glass sliding interior door systems and related accessories. The company is constantly expanding its product range and introducing innovative solutions. For many of its products, the company filed for patent rights. The products of Tycho are characterized by the highest quality. Thanks to optimized manufacturing processes, they can also be offered at a competitive price. Tycho manufactures products that are easy to assemble and ensure high user comfort. Their durability and safety are backed by a 5-year warranty.\n
The company Tycho was founded in 1981. At the beginning it produced products for the automotive industry. In 1997, the company’s core business shifted to sliding and folding door systems. The online shop, which was put into operation in 2016, expanded the direct sales network.
`,


    'shipping_page': `
## Shipping and Payment
### &nbsp;
#### Deliveries are possible to addresses in
#### Austria
#### Belgium
#### Croatia
#### Denmark
#### Estonia
#### Italy
#### Latvia
#### Lithuania
#### Germany
#### Slovakia
#### Slovenia
#### Sweden
#### Switzerland
#### United Kingdom
#### and the Netherlands.
### &nbsp;
##### Payment is possible via PayPal, credit card and other payment methods depending on your region.
##### For Buyers in the European Union, the prices quoted in the product pages include VAT.
##### For Buyers in Switzerland and the UK, the products are VAT zero rated.\n
### &nbsp;
`,
    // ##### Austria, Belgium, Germany and the Netherlands.



    'imprint_page': `
## Imprint
### &nbsp;
#### Tycho Krzysztof Włodarek
#### Proprietor: Krzysztof Marek Włodarek
#### VAT No.: PL8220001767
#### &nbsp;
#### ul. Kościelna 32E
#### 05-311 Dębe Wielkie
#### Poland
#### &nbsp;
#### Phone: +49 152 183 93477
#### E-Mail: <hello@tycho.tech>
#### &nbsp;
### &nbsp;
###### The European Commission provides a platform for online dispute resolutions (ODR) which can be accessed at https&#65279;://ec.europa.eu/consumers/odr/. In order to settle disputes arising from a contractual relationship with a consumer or from whether such a contractual relationship exists at all, we will participate in dispute settlement proceedings before a consumer dispute resolution body. Consumers can contact their national European Consumer Centre in this regard. The respective contact details of the individual ECCs can be found at https&#65279;://www.evz.de/en/alternative-dispute-resolution/adr-in-europe/.
`,



    'cancellation_page': `
## Cancellation
#### &nbsp;
#### &nbsp;
**Right to cancel**

You have the right to cancel this contract within 30 days without giving any reason.

The cancellation period will expire after 30 days from the day on which you acquire, or a third party other than the carrier and indicated by you acquires, physical possession of the goods.

To exercise the right to cancel, you must inform us (Tycho Krzysztof Włodarek, ul. Kościelna 32E, 05-311 Dębe Wielkie, Poland, hello@tycho.tech, Phone: +49 152 183 93477) of your decision to cancel this contract by a clear statement (e.g. a letter sent by post, fax or e-mail). You may use the attached model cancellation form.

To meet the cancellation deadline, it is sufficient for you to send your communication concerning your exercise of the right of cancel before the cancellation period has expired.

#### &nbsp;
**Effects of cancellation**

If you cancel this contract, we will reimburse to you all payments received from you, including the costs of delivery (except for the supplementary costs arising if you chose a type of delivery other than delivery other than the least expensive type of standard delivery offered by us), without undue delay and in any event not later than 30 days from the day on which we are informed about your decision to withdraw from this contract. We will make the reimbursement using the same means of payment as you used for the initial transaction, unless you have expressly agreed otherwise; in any event, you will not incur any fees as a result of such reimbursement. We may withhold reimbursement until we have received the goods back or you have supplied evidence of having sent back the goods, whichever is the earliest.

You shall send back the goods or hand them over to us, without undue delay and in any event not later than 30 days from the day on which you communicate your cancellation from this contract to us. The deadline is met if you send back the goods before the period of 30 days has expired.

You must return or hand over the goods to us immediately and in any event no later than 30 days from the day on which you inform us of the cancellation of this contract. The deadline is met if you send back the goods before the period of 30 days has expired.

We will bear the cost of returning the goods, but the following conditions must be met:

1. You must notify us by email (hello@tycho.tech) or by telephone of your desire to return a package.
2. We will email you a return label that you must print out and stick on the packaged products.
3. Go to the nearest GLS ParcelShop to drop off the parcel. (GLS ParcelShops can be found at https://www.gls-one.de/de/parcelShops)
4. As soon as the package reaches us, you will get the money back.


#### &nbsp;
**Model cancellation form**

*If you want to cancel the contract, please copy and fill out the form below and send it back to us*
##### – To: Tycho Krzysztof Włodarek, ul. Kościelna 32E, 05-311 Dębe Wielkie, Poland, hello@tycho.tech
##### – I/We hereby give notice that I/We cancel my/our contract of sale of the following goods:               
##### – Received on:  
##### – Name of consumer(s):  
##### – Address of consumer(s):  
##### – Date:  
##### – Signature of consumer(s) (only when notified on paper):  

#### &nbsp;
#### &nbsp;
*The Cancellation Policy has been prepared with the Trusted Shops’ Legal Text Generator in cooperation with Wilde Beuger Solmecke Rechtsanwälte.*

`,



    'data_protection_page': `
## Privacy Policy
#### &nbsp;
##### Thank you for visiting our online shop. Protection of your privacy is very important to us. Below you will find extensive information about how we handle your data.
#### &nbsp;
#### &nbsp;
**1. Access data and hosting**

You may visit our website without revealing any personal information. With every visit on the website, the web server stores automatically only a so-called server log file which contains e.g. the name of the requested file, your IP address, the date and time of the request, the volume of data transferred and the requesting provider (access data), and documents the request.

These access data are analysed exclusively for the purpose of ensuring the smooth operation of the website and improving our offer. This serves according to art. 6 (1) 1 lit f GDPR the protection of our legitimate interests in the proper presentation of our offer that are overriding in the process of balancing of interests. All access data are deleted no later than seven days after the end of your visit on our website.

#### &nbsp;
**2. Data collection and use for processing the contract**

We collect personal data that you disclose to us whenever you place an order or contact us (e.g. via contact form or by email). Mandatory fields are marked as such because we absolutely need those data to perform the contract or process your contact request, and you would otherwise not be able to complete your order or send the contact request. It is evident in each input form what data are collected. We use the data that you disclose to us to perform the contract and process your enquiries according to art. 6 (1) 1 lit b GDPR. Upon contract completion, any further processing of your data will be restricted, and your data will be deleted upon expiry of any retention period applicable under relevant statutory regulations, unless you expressly consent to the further use of your data or we reserve the right to further use your data in the scope and manner permitted by law, of which we inform you in this notice.

#### &nbsp;
**3. Transfer of data**

We disclose your data to the shipping company in the scope required for the delivery of the ordered goods according to art. 6 (1) 1 lit. b GDPR. Depending on the payment service provider you have selected during the ordering process, we disclose the payment details collected for order processing purposes to the bank commissioned to handle the payment and, as the case may be, to the payment service provider commissioned by us or to the selected payment service. Some of those data are collected by the selected payment service providers themselves if you open an account with them. In such a case, during the ordering process, you must register with your payment service provider using your access data. In this respect, the privacy notice of the relevant payment service provider applies.

Disclosure of data to a shipping provider
If, when or after placing your order, you have given your express consent to us doing so, we disclose your e-mail address and phone number to the selected shipping provider based on that consent according to art. 6 (1) 1 lit. a GDPR, in order to enable the shipping provider to contact you to advise you of the delivery or agree with you the delivery details.

You may revoke your consent at any time by sending a message to the contact option described below or by directly notifying the shipping provider at the contact address specified below. After you revoke your consent, we will delete the data disclosed for this purpose, unless you expressly consent to the further use of your data or we reserve the right to further use your personal data in the scope and manner permitted by the law, of which we inform you in this notice.

#### Furgonetka Sp. z o. o. Sp. k.
#### ul. Inżynierska 8
#### 03-422 Warszawa
#### Poland

#### &nbsp;
**4. Integration of the Trusted Shops Trustbadge**

We have integrated the Trusted Shops Trustbadge on this website in order to display our Trusted Shops Trustmark and offer the Trusted Shops products to customers after placing an order.

This serves the protection of our legitimate interests in the optimal marketing of our offer according to art. 6 (1) 1 lit f GDPR that are overriding in the process of balancing of interests. The Trustbadge and the advertised trust badge services are offered by Trusted Shops GmbH, Subbelrather Str. 15C, 50823 Cologne, Germany.
With every use of the Trustbadge, the web server automatically saves a so-called server log file which contains e.g. your IP address, the date and time of the request, the volume of data transferred and the requesting provider (access data), and documents the request. Those access data are not analysed and are automatically overwritten no later than seven days after the end of your website visit.
Other personal data are transferred to Trusted Shops only if you decide to use or have already registered to use Trusted Shops products after placing an order. In such a case, the contract concluded between you and Trusted Shops applies.

#### &nbsp;
**5. Cookies and web-analysis**

To improve the user experience on our website and enable you to use its certain features in order to show suitable products or conduct market research, some pages of this website use the so-called cookies. This serves the protection of our legitimate interests in the optimised presentation of our offer according to art. 6 (1) 1 lit a GDPR that are overriding in the process of balancing of interests. A cookie is a small text file which is stored automatically on your end device. Some of the cookies we use are deleted after you close the browser session, i.e. when you close the browser (that’s the so-called session cookies). Other cookies are stored in your end-user device and enable us to recognise your browser when you visit us again (persistent cookies). To check the cookie storage period, you can use the Overview function in the cookie settings of your web browser. You can configure your browser for it to inform you whenever a page uses cookies and decide on a case-by-case basis whether to accept or reject the cookies on a given website or generally. Every browser has a different policy for managing the cookie settings. The browser’s policy is described in the Help menu of every browser and explains how you can change your cookie settings. To find out how to change the settings in your browser, see the links below:
Internet Explorer™: https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies
Safari™: https://support.apple.com/kb/PH21411?locale=de_DE&viewlocale=en_US
Chrome™: https://support.google.com/chrome/answer/95647?hl=en&hlrm=en
Firefox™: https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences
Opera™ : http://help.opera.com/Windows/10.20/en/cookies.html

Please note that disabling cookies may limit your access to some features of our website.

Using of Google (Universal) Analytics for web analytics
Insofar as you have given your consent according to art. 6 (1) 1 lit a GDPR, this website uses Google (Universal) Analytics, a web analytics service provided by Google LLC (www.google.com) for the purpose of website analytics. Google (Universal) Analytics uses methods, like e.g. cookies, that enable an analysis of your use of the website. The information collected automatically by cookies about your use of this website are as a rule transmitted to and stored on a Google server in the United States. At the same time, as IP anonymisation is enabled on this website, the IP address will be shortened before being transmitted within the area of member states of the European Union or other parties to the Agreement on the European Economic Area. Only in exceptional cases, the full IP address will be sent to a Google server in the USA and shortened there. Generally, Google does not associate the anonymised IP address, transmitted from your browser through Google Analytics, with any other data held by Google.

Google LLC is headquartered in the USA and is certified to the EU-US-Privacy Shield. You will see the up-to-date certificate here. Based on this agreement between the USA and the European Commission, the latter has recognised entities certified to the Privacy Shield as those ensuring an adequate level of data protection.

You may revoke your consent at any time with future effect by downloading and installing the browser plug that is available at the following link: http://tools.google.com/dlpage/gaoptout?hl=de.This prevents the collection of data generated by the cookie and related to your use of the website (including your IP address) to Google and the processing of this data by Google.

Alternatively to the browser plugin, you may click this link, to prevent Google Analytics from recording your data on this website in the future. In this process, an opt-out cookie will be stored on your end-user device. If you clear your cookies, you will be asked to provide your consent again.

#### &nbsp;
**6. Contact possibilities and your rights**

You are entitled to be obtain free-of-charge information concerning data stored about your person and, as the case may be, to correct, restrict the processing, enable the portability of, or delete those data.

If you have any questions about how we collect, process or use your personal data, want to enquire about, correct, block or delete your data, or withdraw any consents you have given, or opt-out of any particular data use, please contact us directly using the contact data provided in our site notice.

You may also submit a complaint to the responsible data protection supervisory authority.

#### &nbsp;
**Right to object**

If we process personal data as described above to protect our legitimate interests that are overriding in the process of balancing of interests, you may object to such data processing with future effect. If your data are processed for direct marketing purposes, you may exercise this right at any time as described above. If your data are processed for other purposes, you have the right to object only on grounds relating to your particular situation.

After you have exercised your right to object, we will no longer process your personal data for such purposes unless we can demonstrate compelling legitimate grounds for the processing which override your interests, rights and freedoms or for the establishment, exercise or defence of legal claims.

This does not apply to the processing of personal data for direct marketing purposes. In such a case we will no longer process your personal data for such purposes.

#### &nbsp;
*The Privacy Policy has been prepared with the Trusted Shops’ Legal Text Generator in cooperation with Wilde Beuger Solmecke Rechtsanwälte.*
`,




    'terms_and_conditions_page': `
## Allgemeine Geschäftsbedingungen
#### &nbsp;
#### &nbsp;
**1. Geltungsbereich**

Für alle Bestellungen über unseren Online-Shop durch Verbraucher und Unternehmer gelten die nachfolgenden AGB.

Verbraucher ist jede natürliche Person, die ein Rechtsgeschäft zu Zwecken abschließt, die überwiegend weder ihrer gewerblichen noch ihrer selbständigen beruflichen Tätigkeit zugerechnet werden können. Unternehmer ist eine natürliche oder juristische Person oder eine rechtsfähige Personengesellschaft, die bei Abschluss eines Rechtsgeschäfts in Ausübung ihrer gewerblichen oder selbständigen beruflichen Tätigkeit handelt.

Gegenüber Unternehmern gelten diese AGB auch für künftige Geschäftsbeziehungen, ohne dass wir nochmals auf sie hinweisen müssten. Verwendet der Unternehmer entgegenstehende oder ergänzende Allgemeine Geschäftsbedingungen, wird deren Geltung hiermit widersprochen; sie werden nur dann Vertragsbestandteil, wenn wir dem ausdrücklich zugestimmt haben.

#### &nbsp;
**2. Vertragspartner, Vertragsschluss**

Der Kaufvertrag kommt zustande mit Tycho Krzysztof Włodarek.

Mit Einstellung der Produkte in den Online-Shop geben wir ein verbindliches Angebot zum Vertragsschluss über diese Artikel ab. Sie können unsere Produkte zunächst unverbindlich in den Warenkorb legen und Ihre Eingaben vor Absenden Ihrer verbindlichen Bestellung jederzeit korrigieren, indem Sie die hierfür im Bestellablauf vorgesehenen und erläuterten Korrekturhilfen nutzen. Der Vertrag kommt zustande, indem Sie durch Anklicken des Bestellbuttons das Angebot über die im Warenkorb enthaltenen Waren annehmen. Unmittelbar nach dem Absenden der Bestellung erhalten Sie noch einmal eine Bestätigung per E-Mail.

#### &nbsp;
**3. Vertragssprache, Vertragstextspeicherung**

Die für den Vertragsschluss zur Verfügung stehenden Sprachen sind Deutsch und Englisch.

Wir speichern den Vertragstext und senden Ihnen die Bestelldaten und unsere AGB per E-Mail zu. Der Vertragstext ist aus Sicherheitsgründen nicht mehr über das Internet zugänglich.

#### &nbsp;
**4. Lieferbedingungen**

Zuzüglich zu den angegebenen Produktpreisen kommen noch Versandkosten hinzu. Näheres zur Höhe der Versandkosten erfahren Sie bei den Angeboten.

Wir liefern nur im Versandweg. Eine Selbstabholung der Ware ist leider nicht möglich.

Wir liefern nicht an Packstationen.

#### &nbsp;
**5. Bezahlung**

In unserem Shop stehen Ihnen grundsätzlich die folgenden Zahlungsarten zur Verfügung:

PayPal
Im Bestellprozess werden Sie auf die Webseite des Online-Anbieters PayPal weitergeleitet. Um den Rechnungsbetrag über PayPal bezahlen zu können, müssen Sie dort registriert sein bzw. sich erst registrieren, mit Ihren Zugangsdaten legitimieren und die Zahlungsanweisung an uns bestätigen. Nach Abgabe der Bestellung im Shop fordern wir PayPal zur Einleitung der Zahlungstransaktion auf.
Die Zahlungstransaktion wird durch PayPal unmittelbar danach automatisch durchgeführt. Weitere Hinweise erhalten Sie beim Bestellvorgang.

Sofort
Nach Abgabe der Bestellung werden Sie auf die Webseite des Online-Anbieters Sofort GmbH weitergeleitet. Um den Rechnungsbetrag über Sofort bezahlen zu können, müssen Sie über ein für die Teilnahme an Sofort freigeschaltetes Online-Banking-Konto mit PIN/TAN-Verfahren verfügen, sich entsprechend legitimieren und die Zahlungsanweisung an uns bestätigen. Weitere Hinweise erhalten Sie beim Bestellvorgang. Die Zahlungstransaktion wird unmittelbar danach von Sofort durchgeführt und Ihr Konto belastet.

#### &nbsp;
**6. Eigentumsvorbehalt**

Die Ware bleibt bis zur vollständigen Bezahlung unser Eigentum.
Für Unternehmer gilt ergänzend: Wir behalten uns das Eigentum an der Ware bis zur vollständigen Begleichung aller Forderungen aus einer laufenden Geschäftsbeziehung vor. Sie dürfen die Vorbehaltsware im ordentlichen Geschäftsbetrieb weiterveräußern; sämtliche aus diesem Weiterverkauf entstehenden Forderungen treten Sie – unabhängig von einer Verbindung oder Vermischung der Vorbehaltsware mit einer neuen Sache – in Höhe des Rechnungsbetrages an uns im Voraus ab, und wir nehmen diese Abtretung an. Sie bleiben zur Einziehung der Forderungen ermächtigt, wir dürfen Forderungen jedoch auch selbst einziehen, soweit Sie Ihren Zahlungsverpflichtungen nicht nachkommen.

#### &nbsp;
**7. Transportschäden**

Für Verbraucher gilt:
Werden Waren mit offensichtlichen Transportschäden angeliefert, so reklamieren Sie solche Fehler bitte möglichst sofort beim Zusteller und nehmen Sie bitte unverzüglich Kontakt zu uns auf. Die Versäumung einer Reklamation oder Kontaktaufnahme hat für Ihre gesetzlichen Ansprüche und deren Durchsetzung, insbesondere Ihre Gewährleistungsrechte, keinerlei Konsequenzen. Sie helfen uns aber, unsere eigenen Ansprüche gegenüber dem Frachtführer bzw. der Transportversicherung geltend machen zu können.

Für Unternehmer gilt:
Die Gefahr des zufälligen Untergangs und der zufälligen Verschlechterung geht auf Sie über, sobald wir die Sache dem Spediteur, dem Frachtführer oder der sonst zur Ausführung der Versendung bestimmten Person oder Anstalt ausgeliefert haben. Unter Kaufleuten gilt die in § 377 HGB geregelte Untersuchungs- und Rügepflicht. Unterlassen Sie die dort geregelte Anzeige, so gilt die Ware als genehmigt, es sei denn, dass es sich um einen Mangel handelt, der bei der Untersuchung nicht erkennbar war. Dies gilt nicht, falls wir einen Mangel arglistig verschwiegen haben.

#### &nbsp;
**8. Gewährleistung und Garantien**

Es gilt das gesetzliche Mängelhaftungsrecht. Informationen zu gegebenenfalls geltenden zusätzlichen Garantien und deren genaue Bedingungen finden Sie jeweils beim Produkt und auf besonderen Informationsseiten im Onlineshop.

#### &nbsp;
**9. Haftung**

Für Ansprüche aufgrund von Schäden, die durch uns, unsere gesetzlichen Vertreter oder Erfüllungsgehilfen verursacht wurden, haften wir stets unbeschränkt

bei Verletzung des Lebens, des Körpers oder der Gesundheit,
bei vorsätzlicher oder grob fahrlässiger Pflichtverletzung,
bei Garantieversprechen, soweit vereinbart, oder
soweit der Anwendungsbereich des Produkthaftungsgesetzes eröffnet ist.
Bei Verletzung wesentlicher Vertragspflichten, deren Erfüllung die ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglicht und auf deren Einhaltung der Vertragspartner regelmäßig vertrauen darf, (Kardinalpflichten) durch leichte Fahrlässigkeit von uns, unseren gesetzlichen Vertretern oder Erfüllungsgehilfen ist die Haftung der Höhe nach auf den bei Vertragsschluss vorhersehbaren Schaden begrenzt, mit dessen Entstehung typischerweise gerechnet werden muss. Im Übrigen sind Ansprüche auf Schadensersatz ausgeschlossen.

#### &nbsp;
**10. Verhaltenskodex**

Folgenden Verhaltenskodizes haben wir uns unterworfen:
Trusted Shops Qualitätskriterien
https&#65279;://www.trustedshops.com/tsdocument/%20TS_QUALITY_CRITERIA_de.pdf

#### &nbsp;
**11. Streitbeilegung**

Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die Sie hier finden https&#65279;://ec.europa.eu/consumers/odr/.
Zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle sind wir nicht verpflichtet und nicht bereit.

#### &nbsp;
**12. Schlussbestimmungen**

Sind Sie Kaufmann im Sinne des Handelsgesetzbuches, juristische Person des öffentlichen Rechts oder öffentlich-rechtliches Sondervermögen, ist ausschließlicher Gerichtsstand für alle Streitigkeiten aus Vertragsverhältnissen zwischen uns und Ihnen unser Geschäftssitz.

#### &nbsp;
*AGB erstellt mit dem Trusted Shops Rechtstexter in Kooperation mit Wilde Beuger Solmecke Rechtsanwälte.*

`

}