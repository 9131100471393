import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { breakpoints } from '../utils/theme'
import Wrapper from '../components/Wrapper'
import { useTranslation } from 'react-i18next'
import { germany } from '../initial/vars'
const slugify = require('slugify')

const StyledFooter = styled.footer`
  color: #bbb;
  background-color: #323548;
  width: 100%;
  padding: 24px 0 48px;
  margin-top: auto;
  font-size: 14px;
  line-height: 18px;
  z-index: 99;

  @media (max-width: ${breakpoints.mobile}px) {
    padding: 24px 0 0;
  }
`

const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;

  @media (max-width: ${breakpoints.mobile}px) {
    flex-direction: column;
  }
`

const Item = styled.div`
  display: block;
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
  padding: 16px 0;

  @media (max-width: ${breakpoints.mobile}px) {
    flex: initial;
    max-width: initial;
    padding: 0 0 24px;
    margin: 0 0 12px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
`

const ItemLast = styled.div`
  margin-left: 16.66667%;
  display: block;
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
  padding: 16px 0;

  > a {
    color: inherit;
  }

  @media (max-width: ${breakpoints.mobile}px) {
    flex: initial;
    max-width: initial;
    margin-left: initial;
    padding: 0 0 24px;
    margin: 0 0 12px;
  }
`

const Ul = styled.ul`
  list-style: none;
  margin: 0;
`

const Li = styled.ul`
  padding: 0;
  margin: 0 0 8px;
`

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`

const Footer = () => {

  const { t, i18n } = useTranslation()
  const curretnLang = i18n.language
  const prefix = germany ? curretnLang + '/' : ''

  const slug = (name) => {
    return slugify(t(`product::${name}`), { lower: true }).replace(/[^a-z0-9-]/gi, '')
  }

  return (
    <StyledFooter>
      <Wrapper>
        <Flex>
          <Item>
            <Ul>
              <Li>
                <StyledLink to={`/${prefix}${slug('O firmie')}/`} >
                  {t(`product::O firmie`)}
                </StyledLink>
              </Li>
              <Li>
                <StyledLink to={`/${prefix}${slug('Produkty')}/`} >
                  {t(`product::Produkty`)}
                </StyledLink>
              </Li>
            </Ul>
          </Item>
          <Item>
            <Ul>
              <Li>
                <StyledLink to={`/${prefix}${slug('Regulamin')}/`} >
                  {t(`product::Regulamin`)}
                </StyledLink>
              </Li>
              <Li>
                <StyledLink to={`/${prefix}${slug('Odstąpienie od umowy')}/`} >
                  {t(`product::Odstąpienie od umowy`)}
                </StyledLink>
              </Li>
              <Li>
                <StyledLink to={`/${prefix}${slug('Polityka prywatności')}/`} >
                  {t(`product::Polityka prywatności`)}
                </StyledLink>
              </Li>
              <Li>
                <StyledLink to={`/${prefix}${slug('Koszty dostawy')}/`} >
                  {t(`product::Koszty dostawy`)}
                </StyledLink>
              </Li>
              {germany &&
                <Li>
                  <StyledLink to={`/${prefix}${slug('Imprint')}/`} >
                    {t(`product::Imprint`)}
                  </StyledLink>
                </Li>
              }
            </Ul>
          </Item>

          <ItemLast>
            {!germany &&
              <>
                Tycho Krzysztof Włodarek <br />
              ul. Kościelna 32E <br />
              05-311 Dębe Wielkie, Polska <br />
                <a href="tel:+48-513-589-347">+48 513 589 347</a> <br />
                <a href="mailto:sklep@tycho.pl">sklep@tycho.pl</a>
                <br />
                <br />
              </>
            }
            {germany ?
              <p> Copyright © {new Date().getFullYear()} Tycho </p>
              :
              <p> © Tycho {new Date().getFullYear()} </p>
            }
          </ItemLast>
        </Flex>
      </Wrapper>
    </StyledFooter>
  )
}


export default Footer