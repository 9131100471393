import React from 'react'
import styled from 'styled-components'
import { breakpoints } from '../utils/theme'

const BigTypoContainer = styled.div`
  font-size: 24px;
  line-height: 1.28571428571; 
  ${({ oneline }) => oneline && `text-overflow: ellipsis`};
  ${({ oneline }) => oneline && `white-space: nowrap`};
  ${({ oneline }) => oneline && `overflow: hidden`};

  @media (max-width: ${breakpoints.mobile + 'px'}) {
    font-size: 20px;
  }

`
export default function BigTypo({ text, style, oneline }) {
  return (
    <BigTypoContainer oneline={oneline} style={style}>
      {text}
    </BigTypoContainer>
  )
}
