import React from 'react'

export default function MenuIcon({ showDrawer }) {
  return (
    <button
      onClick={showDrawer}
      style={{
        cursor: 'pointer',
        padding: 0,
        border: 0,
        height: '36px',
        width: '36px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'none',
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="14"
        viewBox="0 0 16 14"
      >
        <rect width="16" height="2" fill="#2a2954" />
        <rect width="16" height="2" transform="translate(0 6)" fill="#2a2954" />
        <rect width="8" height="2" transform="translate(0 12)" fill="#2a2954" />
      </svg>
    </button>
  )
}
