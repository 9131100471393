import React from 'react'
import Search from '../../components/searchContainer'
import styled from 'styled-components'

const MenuBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  background: white;
`
const MenuBody = () => {
  return (
    <MenuBodyWrapper>
      <Search />
    </MenuBodyWrapper>
  )
}

export default MenuBody
