import React from 'react'
import styled from 'styled-components'
import { breakpoints, maxWidth } from '../utils/theme'

const StyledWrapper = styled.div`
  max-width: ${maxWidth}px;
  margin: auto;
  padding: ${({ productList }) => productList ? '0 32px' : '0 48px'};

  @media (max-width: ${breakpoints.mobile + 'px'}) { 
    padding: ${({ productList }) => productList ? '0 6px' : '0 14px'};
  }
`

const Wrapper = ({ children, productList, style }) => {
  return (
    <StyledWrapper
      productList={productList}
      style={{ ...style }}
    >
      {children}
    </StyledWrapper>
  )
}

export default Wrapper