import React from 'react'
import styled from 'styled-components'
import { borderGray } from '../../utils/theme'

const Button = styled.div`
  background-color: white;
  cursor: pointer;
  width: 35px;
  height: 35px;
  border: 1px solid ${borderGray};
  margin-right: -1px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Minus = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="7.12"
    height="1.288"
    viewBox="0 0 7.12 1.288"
  >
    <path
      id="Path_10317"
      data-name="Path 10317"
      d="M13.269,8.805H6.149V7.518h7.12Z"
      transform="translate(-6.149 -7.518)"
      fill="#a6a6a6"
    />
  </svg>
)

const Plus = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8.532"
    height="9.063"
    viewBox="0 0 8.532 9.063"
  >
    <path
      id="Path_10318"
      data-name="Path 10318"
      d="M10.626,8.389H14.1v1.5H10.626v3.933H9.036V9.886H5.565v-1.5H9.036V4.756h1.59Z"
      transform="translate(-5.565 -4.756)"
      fill="#a6a6a6"
    />
  </svg>
)

const ChangeQtyBtn = ({ onClick, minus, plus }) => (
  <Button onClick={onClick}>
    {minus && <Minus />}
    {plus && <Plus />}
  </Button>
)

export default ChangeQtyBtn
