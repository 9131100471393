module.exports = {
      'save': 'Zapisz',
      'billing': 'Dane kupującego',
      'delivery': 'Dostawa',
      'almost_ready': 'Prawie gotowe',
      'customer_data': 'Dane kupującego i dostawa',
      'company_purchase': 'Zakup na firmę',
      'ship_to_different_address': 'Inny adres dostawy',
      'your_order': 'Twoje zamówienie',
      'your_cart_is_empty': 'Twój koszyk jest pusty',
      'payment': 'Płatność',
      'customer_note_placeholder': 'Uwagi do zamówienia, np. informacje o dostarczeniu przesyłki.',
      'cart_subtotal': 'Wartość zamówienia',
      'shipping_total': 'Dostawa',
      'order_total': 'Suma',
      'cod': 'Pobranie',
      'place_order': 'Kupuję i płacę',
      'complete_purchase': 'Kupuję i płacę',

      'incl': 'Zawiera',
      'vat': 'podatku VAT',
      'incl_vat': 'Zawiera podatek VAT',
      'by_clicking_place_order_button_you_accept_our': 'Klikając przycisk Kupuję i płacę, akceptujesz nasz',
      'terms_and_conditions': 'Regulamin.',
      'we_will_process_your_data_in_accordance_with_the': 'Twoje dane będziemy przetwarzać zgodnie z',
      'privacy_policy-text': 'W celu zapewnienia wyższej jakości usług na tej stronie użyto plików cookie. Dowiedz się więcej z zakładki',
      'privacy_policy': 'Polityka Prywatności',
      'privacy_policy-LINK': '/polityka-prywatnosci',
      'accept-cookies': 'Akceptuj pliki cookie',
      'close': 'Zamknij',
      'required_field': 'Pole wymagane',
      'incorrect_email_address': 'Nieprawidłowy adres e-mail',
      'incorrect_phone_number': 'Nieprawidłowy numer telefonu',
      'incorrect_postal_code': 'Nieprawidłowy kod pocztowy',
      'incorrect_vat_number': 'Nieprawidłowy nr NIP',
      'street_building_no_apartment': 'ulica, nr budynku, lokalu',
      'street_and_building_number_required': 'Ulica i numer budynku są wymagane',
      'optional': 'opcjonalnie',
      'promo_code': 'Kod promocyjny',

      'first_name': 'Imię',
      'last_name': 'Nazwisko',
      'email': 'E-mail',
      'phone': 'Telefon',
      'address_1': 'Adres',
      'address': 'Adres',
      'city': 'Miasto',
      'state': 'Stan',
      'postcode': 'Kod pocztowy',
      'country': 'Kraj',
      'company': 'Firma',
      'vat_number': 'Numer NIP ',
      'PL': 'Polska',
      'DE': 'Niemcy',
      'AT': 'Austria',
      'NL': 'Holandia',
      'BE': 'Belgia',
      
      'DK': 'Dania',
      'UK': 'Zjednoczone Królestwo',
      'SE': 'Szwecja',
      'SK': 'Słowacja',
      'CH': 'Szwajcaria',

      'billing_and_shipping_country_must_be_the_same': 'Kraj rozliczeniowy i kraj wysyłki muszą być takie same',
     
      'thank_you': 'Dziękujemy.',
      'order_received': 'Otrzymaliśmy Twoje zamówienie.',
      'order_number': 'Numer zamówienia',
      'date': 'Data',
      'payment_method': 'Metoda płatności',
      'order_details': 'Szczegóły zamówienia',
      'quantity': 'Ilość',
      'total': 'Suma',
      'bacs': 'Tradycyjny przelew bankowy',
      'cod': 'Za pobraniem',
      'transferuj': 'Płatność online Tpay',


}